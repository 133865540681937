.conversations {
	& > div {
		display: grid;
		grid-template-columns: 1fr 2fr;
		height: 600px;
		background-color: $white;
	}
	.messages {
		padding: 20px 10px;
		background-color: $darkblue;
		@include breakpoint($tablet) {
			padding: 20px;
		}
		
		.btn {
			width: 100%;
			margin-bottom: 30px;
		}
		.scrollarea {
			height: 500px;
			padding-right: 10px;
			margin-left: -20px;
			margin-right: -10px;
		}
		.convItemList {
			li {
				position: relative;
				display: block;
				height: 60px;
				width: 100%;
				z-index: 1;
				@include breakpoint($tablet) {
					padding-left: 15px;
					border-left: 5px solid transparent;
				}
				
				a {
					display: block;
					height: 100%;
					padding-top: 10px;
					border-top: 1px solid $medblue;
					color: $white;
				}
				label {
					display: block;
					max-width: 80%;
					font-size: 16px;
					font-weight: $bold;
					line-height: 24px;
					overflow: hidden;
					text-overflow: ellipsis;
					@include breakpoint($tablet) {
						font-size: 12px;
						line-height: 18px;
					}
					
					&.highlight, &.caption {
						display: inline-block;
						font-size: 12px;
						font-weight: $regular;
						line-height: 18px;
						@include breakpoint($tablet) {
							font-size: 11px;
							line-height: 17px;
						}
					}
					&.highlight {
						margin-right: 3px;
					}
					&.caption {
						color: $white;
					}
				}
				span, time {
					position: absolute;
					top: 10px;
					right: 0;
					font-size: 10px;
					color: $blue;
				}
				
				&.selected {
					@include breakpoint($tablet) {
						border-left-color: $blue;
						
						&:before {
							content: " ";
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 65px;
							@include bkgd-gradient(90deg, $blue, transparent);
							opacity: 0.25;
							z-index: -1;
						}
					}
				}
				
				&.unread {
					a {
						position: relative;
						padding-left: 15px;
						
						&:after {
							content: " ";
							position: absolute;
							top: 16px;
							left: 0;
							height: 9px;
							width: 9px;
							@include border-radius(50%);
							background-color: $blue;
							@include breakpoint($tablet) {
								top: 14px;
							}
						}
					}
				}
			}
		}
	}
	.newConversation {
		align-self: start;
		padding: 20px;
		background-color: $blue;
		color: $white;
		@include breakpoint($tablet) {
			padding: 20px 70px;
		}
		
		.fields {
			grid-template-columns: 60px 1fr;
			
			label {
				grid-column: 1;
				line-height: 30px;
				color: $white;
			}
			input[type="text"], textarea, .autocomplete {
				grid-column: 2;
				background-color: $white;
			}
			textarea {
				grid-column: 2;
			}
		}
		svg {
			display: none;
			position: absolute;
			bottom: -75%;
			left: 50%;
			transform: translate(-50%, 50%);
			width: 185px;
			height: 189px;
			fill: $meddarkgrey;
			@include breakpoint($tablet) {
				display: inline-block;
			}
		}
	}
	.conversation {
		background-color: $white;
		
		.thumb {
			display: inline-block;
			height: 28px;
			width: 28px;
			margin-right: 10px;
			@include border-radius(50%);
			background-color: $blue;
			font-size: 10px;
			font-weight: $bold;
			line-height: 28px;
			color: $white;
			text-align: center;
			text-transform: uppercase;
			overflow: hidden;
			vertical-align: middle;
			
			img {
				height: 100%;
				width: 100%;
			}
		}
		.newMessage {
			padding: 20px;
			background-color: $blue;
			@include breakpoint($tablet) {
				padding: 20px 40px;
			}
			
			.thumb {
				background-color: $darkblue;
				color: $white;
			}
			input[type="text"] {
				width: calc(100% - 38px);
				margin: 0;
				background-color: $white;
			}
		}
		.scrollarea {
			height: 530px;
			padding-right: 0;
		}
		.message {
			padding: 20px;
			border-top: 1px solid $lightgrey;
			@include breakpoint($tablet) {
				padding: 20px 40px;
			}
			
			&:first-of-type() {
				border-top: none;
			}
			
			span {
				font-size: 10px;
				font-weight: $bold;
				color: $blue;
			}
			p {
				width: 75%;
				margin: 10px 0;
				line-height: 20px;
			}
			.author {
				label {
					font-size: 10px;
					font-weight: $bold;
					color: $darkblue;
					
					span {
						position: relative;
						display: inline-block;
						padding: 0 4px;
						margin-left: 8px;
						background-color: $blue;
						font-size: 9px;
						font-weight: $regular;
						line-height: 16px;
						color: $white;
						
						&:before, &:after {
							content: " ";
							position: absolute;
							border-width: 8px 2px;
							border-style: solid;
						}
						&:before {
							left: -4px;
							border-top-color: transparent;
							border-bottom-color: $blue;
							border-left-color: transparent;
							border-right-color: $blue;
						}
						&:after {
							right: -4px;
							border-top-color: $blue;
							border-bottom-color: transparent;
							border-left-color: $blue;
							border-right-color: transparent;
						}
					}
				}
			}
			
			&.mine .thumb {
				background-color: $darkblue;
			}
			&.theirs {
				background-color: #F3F6F8;
			}
		}
	}
}