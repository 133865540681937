button, input[type="submit"] {
	-webkit-appearance: none;
}

button, input[type="submit"], .btn {
	display: inline-block;
	height: 35px;
	padding: 0 10px;
	background-color: $blue;
	border: none;
	@include border-radius(2px);
	font-size: 11px;
	font-weight: $semibold;
	line-height: 35px;
	color: $white;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	cursor: pointer;
	@include breakpoint($tablet) {
		height: 30px;
		line-height: 30px;
	}
	
	svg {
		height: 15px;
		width: 15px;
		margin-right: 5px;
		fill: $white;
		vertical-align: middle;
	}
	
	&:hover {
		opacity: 0.9;
	}
	
	&.secondary {
		background-color: $white;
		color: $blue;
		
		svg {
			fill: $blue;
		}
	}
	&.cancel, &.outline {
		background-color: transparent;
		border: 2px solid $blue;
		color: $blue;
		line-height: 33px;
		@include breakpoint($tablet) {
			line-height: 26px;
		}
		
		svg {
			fill: $blue;
		}
	}
	&.warn {
		background-color: $red;
		color: $white;
	}
	&.disabled {
		opacity: 0.5;
		cursor: auto;
	}
}

/* Looks like a link with an icon */
.btnLink {
	display: inline-block;
	font-size: 14px;
	line-height: 30px;
	
	svg {
		height: 15px;
		width: 15px;
		margin-left: 5px;
		vertical-align: middle;
		fill: $blue;
	}
	
	&:hover {
		opacity: 0.9;
	}
	
	&.add {
		svg {
			fill: $green;
		}
	}
	&.delete {
		svg {
			fill: $red;
		}
	}
	
	&.disabled {
		opacity: 0.5;
		cursor: auto;
	}
}

/* Modal Close X */
.btnClose {
	position: absolute;
	top: 15px;
	right: 10px;
	height: 10px;
	width: 10px;
	line-height: 0;
	@include breakpoint($tablet) {
		right: 20px;
	}

	svg {
		fill: $blue;
	}
}

.linkBack {
	display: inline-block;
	line-height: 36px;
	color: $white;
	
	svg {
		height: 12px;
		width: 8px;
		margin-right: 10px;
		vertical-align: middle;
		fill: $white;
	}
}