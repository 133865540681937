.scrollarea-content {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;

    &:focus {
        outline: 0;
    }
}

.scrollarea {
    position: relative;
    overflow: hidden;
    padding-right: 5px;

    .scrollbar-container {
        position: absolute;
		@include border-radius(4px);
		background-color: $darkgrey;
        opacity: .6;

        -webkit-transition: all .4s;
           -moz-transition: all .4s;
            -ms-transition: all .4s;
             -o-transition: all .4s;
                transition: all .4s;
		
		.scrollbar {
			@include border-radius(4px);
			background: $darkblue;
		}
		
        &.horizontal {
            width: 100%;
            height: 5px;
            left: 0;
            bottom: 0;

            .scrollbar {
                height: 5px;
            }
        }
        &.vertical {
            width: 5px;
            height: 100%;
            right: 0;
            top: 0;

            .scrollbar {
                width: 5px;
            }
        }
    }

    &:hover .scrollbar-container {
        opacity: 1;
    }
}