.landingWrapper {
	margin: -40px -10px -20px;
	@include breakpoint($tablet) {
		min-height: 800px;
		margin: -40px 0;
	}
}
.landing {
	color: $white;
	@include breakpoint($tablet) {
		position: absolute;
		top: 0;
		bottom: 400px;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		background: transparent url("../../../images/marketing/bkgd_Landing.jpg") no-repeat center top;
		background-size: cover;
	}

	img {
		display: block;
		height: 25px;
		margin-bottom: 30px;
	}
	.callout {
		display: inline-block;
		height: 20px;
		padding: 0 10px;
		margin-bottom: 10px;
		background-color: $blue;
		font-size: 10px;
		line-height: 21px;
	}
	h1 {
		margin-bottom: 20px;
		@include breakpoint($tablet) {
			font-size: 40px;
			line-height: 42px;
		}
	}
	p {
		margin-bottom: 20px;
		font-size: 16px;
		line-height: 18px;
	}
	.btn {
		display: block;
		margin-bottom: 10px;
		@include breakpoint($tablet) {
			width: 250px;
		}

		&.outline {
			border-color: $white;
			color: $white;
		}
	}

	& > div {
		padding: 150px 80px;
		@include breakpoint($tablet) {
			width: 50%;
			padding: 0 span(1 of 8);
		}
	}
	.renters {
		@include breakpoint($phone $tablet) {
			background: transparent url("../../../images/marketing/bkgd_LandingRenters.jpg") no-repeat center top;
			background-size: cover;
		}
		@include breakpoint($tablet) {
			padding-bottom: 40px;
		}

		.btn {
			@include bkgd-gradient(90deg, $pink, $blue);
		}
	}
	.businesses {
		@include breakpoint($phone $tablet) {
			background: transparent url("../../../images/marketing/bkgd_LandingBusinesses.jpg") no-repeat center top;
			background-size: cover;
		}

		.callout {
			background-color: $pink;
		}
	}
}
