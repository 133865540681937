.detailView {
  & > div {
    margin-bottom: 20px;

    label {
      display: block;
      font-weight: $bold;
      line-height: 25px;
      color: $blue;
    }
  }
}

.form {
  .fields {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    margin-bottom: 20px;

    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > p {
      grid-column: span 4;
    }
    & > label {
      grid-column: span 4;
      font-weight: $bold;
      line-height: 25px;
      color: $blue;
    }
    input[type="text"],
    input[type="password"],
    select,
    textarea,
    .autocomplete,
    .upload,
    .check,
    .radioList {
      grid-column: span 4;
      min-width: 0;
      margin: 0;

      &.lg {
        grid-column: span 3;
      }
      &.med {
        grid-column: span 2;
      }
      &.sm {
        grid-column: span 1;
      }
    }
    textarea {
      resize: vertical;
      height: 70px;
    }
    .check {
      margin-top: 5px;
    }
    .autocomplete input[type="text"] {
      width: 100%;
      margin: 0;
    }
  }
  .radio-group {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 20px;
    label {
      margin: auto;
    }
  }
  label.radio-group {
    grid-column: span 4;
    font-weight: 700;
    line-height: 25px;
    color: #278ada;
    margin-bottom: 5px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;

  button,
  input[type="submit"],
  .btn {
    grid-column: span 2;

    &.cancel {
      grid-column: span 1;
    }
    &.full {
      grid-column: span 3;
    }
  }
}
