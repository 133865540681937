@media print {
	/* Fix for Chrome using a mobile viewport for print */
	@page {
		size: 330mm 427mm;
		margin: 14mm;
	}
	
	body, tr, td, input, textarea, select, button {
		font-size: 12px;
		line-height: 15px;
	}

	#root:after {
		display: none;
	}
	.container {
		grid-template: auto / auto;
		
		& > aside {
			display: none;
		}
		& > header {
			display: none;
		}
		& > main {
			grid-column: 1;
			grid-row: 1;
			padding: 0;
			
			header {
				grid-template: 160px / repeat(4, 1fr);
				
				h1 {
					color: $darkblue;
				}
				.linkBack {
				}
				.searchFilter, .progress {
				}
			}
		}
		& > footer {
			display: none;
		}
		
		&:before, &:after {
			display: none;
		}
	}
	
	button, input[type="submit"], .btn, .btnLink, .linkBack {
		display: none !important;
	}
	.printOnly {
		display: inherit !important;
	}
}