.mobile_only {
  @include breakpoint($tablet) {
    display: none !important;
  }
}
.hide_mobile_block {
  display: none !important;
  @include breakpoint($tablet) {
    display: block !important;
  }
}
.hide_mobile_inline {
  display: none !important;
  @include breakpoint($tablet) {
    display: inline !important;
  }
}
.hide_mobile_inline_block {
  display: none !important;
  @include breakpoint($tablet) {
    display: inline-block !important;
  }
}
