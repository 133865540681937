.cart {
	header {
		.actions {
			grid-column: 1;
			grid-row: 1;
			align-self: center;
			@include breakpoint($tablet) {
				grid-column: 4;
			}

			.btn {
				background-color: $white;
				color: $blue;

				&.outline {
					grid-column: 3 / span 1;
					border-color: $white;
					background-color: transparent;
					color: $white;

					svg {
						fill: $white;
					}
				}
			}
		}
	}

	.content {
		display: grid;
		grid-gap: 10px;
		@include breakpoint($tablet) {
			grid-template: auto 1fr / 3fr 1fr;
		}
	}

	.progress {
		display: flex;
		flex-direction: column;
		padding: 10px;
		background-color: $darkblue;
		@include breakpoint($tablet) {
			grid-column: 1;
			grid-row: 1;
			flex-direction: row;
			padding: 20px;
		}

		ul {
			display: flex;

			li {
				position: relative;
				margin-right: 15px;
				@include breakpoint($tablet) {
					margin-right: 20px;
				}

				a {
					display: block;
					color: $darkgrey;
					@include breakpoint($tablet) {
						font-size: 11px;
					}

					div, svg {
						display: inline-block;
						height: 30px;
						width: 30px;
						font-size: 12px;
						color: $white;
						vertical-align: middle;
					}
					div {
						@include border-radius(50%);
						border: 3px solid $blue;
						line-height: 24px;
						text-align: center;
					}
					svg {
						position: relative;
						fill: $green;
						z-index: 1;
					}
					label {
						display: none;
						margin-left: 5px;
						@include breakpoint($tablet) {
							display: inline;
						}
					}
				}

				&.active {
					margin-right: auto;
					@include breakpoint($tablet) {
						margin-right: 20px;
					}

					a {
						color: $white;

						div {
							background-color: $blue;
						}
						label {
							display: inline;
						}
					}
				}
				&.complete {
					a {
						text-decoration: line-through;

						div {
							background-color: $blue;
							color: $white;
						}
					}
					&:before {
						background-color: $green;
					}
					&:after {
						content: " ";
						position: absolute;
						top: 5px;
						left: 5px;
						height: 20px;
						width: 20px;
						background-color: $white;
						z-index: 0;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
		.btn {
			margin-top: 10px;
			@include breakpoint($tablet) {
				margin-top: 0;
				margin-left: auto;
			}

			&.alert {
				background-color: $orange;

				svg {
					fill: $white;
				}
			}
		}
	}
	.itemForm, .eventForm, .summary {
		align-self: start;
		@include breakpoint($tablet) {
			grid-column: 1;
			grid-row: 2;
		}

		.items {
			display: grid;
			padding: 10px;
			background-color: $white;
			grid-template-columns: 1fr 1fr;
			@include breakpoint($tablet) {
				padding: 20px;
				margin-top: 10px;
			}

			.tileToggle {
				grid-column: 2;
				grid-row: 1;
				place-self: center end;
			}
			.companyItems {
				grid-column: span 2;
				position: relative;
				display: grid;
				margin-bottom: 20px;
				@include breakpoint($tablet) {
					grid-template-columns: repeat(3, 1fr);
				}

				strong, table {
					@include breakpoint($tablet) {
						grid-column: span 3;
						align-self: start;
					}
				}
				strong {
					line-height: 40px;

					span {
						color: $blue;
					}
				}
				.grid {
					table-layout: fixed;
					border-bottom: 3px solid $blue;

					.itemContent {
						display: grid;
						grid-template-columns: 90px 1fr 160px;
						grid-gap: 5px 10px;
						align-items: center;
						height: auto;
						min-height: 40px;
						padding: 5px 0;

						label {
							text-overflow: ellipsis;
							overflow: hidden;

							.info {
								margin-left: 5px;
								color: $white;
								vertical-align: middle;
							}
						}
						span {
							grid-column: 3;
						}
					}
          .normalText {
            white-space: normal;
          }
					.mobileItemContent {
						display: grid;
						grid-template-columns: repeat(8, 1fr);
						grid-gap: 5px;
						height: auto;
						padding: 15px 0;
						border-bottom: 1px solid $lightgrey;

						figure {
							grid-column: 1 / span 8;
							@include maintain-aspect-ratio(16, 9);
						}
						label {
							grid-column: 1 / span 7;
							padding: 5px 0;
							white-space: normal;
						}
						.btnLink {
							place-self: start end;
						}
						input[type="text"], select {
							grid-column: span 4;
							margin: 0;
						}
					}
          &.thumbnail .image {
            vertical-align: top;

            figure {
              height: 125px;
            }
          }
					&.thumbnail .itemContent {
						min-height: 125px;
						padding: 30px 0;

						label {
							white-space: normal;
						}
					}
				}
				.billingDetails {
					@include breakpoint($tablet) {
						grid-column-start: 2;
						grid-column-end: none;
					}

					& > div:first-of-type {
						border-top: none;
					}
				}

				.textSpace {
					white-space: break-spaces;
				}

				&:before {
					content: " ";
					position: absolute;
					top: 0;
					left: -25px;
					height: 40px;
					width: 5px;
					background-color: $blue;
				}
			}
		}
	}
	.itemForm {
		.details {
			padding: 10px;
			background-color: $white;
			@include breakpoint($tablet) {
				padding: 20px;
			}

			.form {
				@include breakpoint($tablet) {
					width: 470px;
					margin: 30px 0 30px 120px;
				}

				label {
					display: block;
					font-size: 10px;
					color: $blue;
				}
			}
		}

		&.noItems {
			padding: 10px;
			background-color: $white;
			@include breakpoint($tablet) {
				padding: 20px;
			}

			& > h4 {
				grid-column: span 2;
				text-align: center;
				@include breakpoint($tablet) {
					margin: 20px 0 0;
				}
			}
			.tileToggle {
				display: none;
			}
			.dateRange {
				grid-row: 2;
				@include breakpoint($tablet) {
					margin-top: 0;
				}
			}
			.prompt {
				grid-column: span 2;
				text-align: center;
				@include breakpoint($tablet) {
					margin: 60px 0 40px;
				}

				svg {
					width: 170px;
					margin-bottom: 30px;
					fill: $darkgrey;
				}
				.btn {
					width: 235px;
				}
			}
		}
	}
	.eventForm {
		padding: 10px;
		background-color: $white;
		@include breakpoint($tablet) {
			padding: 10px 20px;
		}

		.form {
			@include breakpoint($tablet) {
				margin: 40px 150px;
			}

			.actions {
				margin-bottom: 20px;
			}
		}
	}
	.eventDetails {
		display: grid;
		grid-gap: 20px;
		padding: 20px;
		background-color: $white;
		@include breakpoint($tablet) {
			grid-template-columns: repeat(3, 1fr);
		}

		.title {
			@include breakpoint($tablet) {
				grid-column: 1 / span 3;
			}

			h3 {
				color: $blue;
			}
		}
		.contact, .venue, .delivery {
			label, strong {
				display: block;
				font-weight: $bold;
			}
			label {
				color: $blue;
			}
		}
		.btn {
			@include breakpoint($tablet) {
				grid-row: 3;
			}
		}
	}
	.billing {
		grid-row: 2;
		align-self: start;
		@include breakpoint($tablet) {
			grid-column: 2;
			grid-row: 1 / span 2;
		}

		.billingSummary, .complete {
			padding: 10px;
			background-color: $white;
			@include breakpoint($tablet) {
				padding: 20px 10px;
			}
		}
		.billingSummary {
			h2 {
				margin-bottom: 20px;
			}
			strong {
				position: relative;
				display: block;
				line-height: 40px;
				color: $blue;

				&:before {
					content: " ";
					position: absolute;
					top: 0;
					left: -15px;
					height: 40px;
					width: 5px;
					background-color: $blue;
				}
			}
			.btn {
				position: relative;
				width: 100%;
				margin-bottom: 10px;

				svg {
					position: absolute;
					top: 50%;
					right: 10px;
					transform: translate(0, -50%);
					fill: $white;
				}
			}
			.billingDetails {
				margin: 0 0 20px;

				.alert {
					grid-template-columns: 25px 1fr;
					border-top: none;
					line-height: 18px;

					svg {
						height: 15px;
						width: 15px;
						margin-right: 5px;
						fill: $orange;
						vertical-align: middle;
					}
					p {
						padding-right: 20px;
					}
				}
			}
			.notes {
				color: $blue;

				h4 {
					margin-bottom: 5px;
					border-bottom: 2px solid $blue;
					font-size: 12px;
					color: $blue;
					line-height: 18px;
				}
			}
		}
		.complete {
			overflow: hidden;

			svg {
				display: none;
				height: 300px;
				margin: -120px 0 20px -10px;
				fill: $blue;
				@include breakpoint($tablet) {
					display: inline-block;
				}
			}
			p:first-of-type() {
				font-weight: $bold;
			}
		}
	}
}

.billingDetails {
	& > div {
		display: grid;
		grid-template-columns: 1fr 1fr;
		border-top: 1px solid $medblue;
		line-height: 39px;

		& > span {
			font-weight: $bold;
			text-align: right;

			.caption {
				margin-right: 10px;
				line-height: initial;
			}
		}
		div {
			grid-column: span 2;
			padding-bottom: 10px;
			font-size: 11px;
			line-height: 16px;

			& > label {
				margin-right: 15px;
			}
			& > span {
				float: right;
			}
		}

		&.subtotal {
			border-top: 2px solid $darkblue;
		}
		&.total {
			position: relative;
			padding-top: 4px;
			border-top: 2px solid $darkblue;

			&:before {
				content: " ";
				position: absolute;
				top: 2px;
				left: 0;
				right: 0;
				border-top: 2px solid $darkblue;
			}
		}
		&.date {
			@include breakpoint($tablet) {
				grid-template-columns: 1fr 2fr;
			}
		}
	}
}

.editRental {
  .eventForm {
    margin: 10px 0;
  }

  .addItemBtn {
    margin-bottom: 20px;
  }

  .content {
    .progress {
      .editOrder {
        color: white;
      }

      .progressBtns{
        margin-left: auto;

        .btn {
          margin-left: 10px;
        }
      }
      
    }
  }
}
