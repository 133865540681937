.r-calendar {
	.r-inner {
		width: 100%;
		height: 100%;
	}

	.r-row {
		@include clearfix;
		margin: 15px 0;
		text-align: center;
		@include breakpoint($tablet) {
			margin: 17px 0;
		}
	}

	.r-cell {
		float: left;
		width: span(1 of 9);
		margin-left: span(1 of 54);
		margin-right: span(1 of 54);
		@include breakpoint($tablet) {
			width: span(1 of 13);
			margin-left: span(1 of 26);
			margin-right: span(1 of 26);
		}

		&:nth-child(1) {
			margin-left: 0 !important;
		}
		&:nth-child(7) {
			margin-right: 0 !important;
		}
		&.r-date {
			color: $darkgrey;
			font-weight: $light;
			cursor: pointer;
			outline: none;

			&.r-today {
				background-color: #0066CC;
			}

			&:not(.r-past):hover {
				background-color: $blue;
				color: $white;
			}

			&.r-highlighted, &.r-alerting  {
				background-color: transparent;
				color: $white;
			}
			&.r-highlighted {
				border: 2px solid $blue;
			}
			&.r-alerting {
				border: 2px solid $orange;
			}

			&.r-selected {
				background-color: $blue;
				color: $white;
			}

			&.r-past {
				color: #999999;
				cursor: not-allowed;
			}
		}
	}

	.r-head {
		font-size: 12px;
		color: $white;
		line-height: 12px;
		text-transform: uppercase;
		margin: 0 0 15px;
		@include breakpoint($tablet) {
			margin: 0 0 17px;
		}

		.r-cell {
			&.r-title {
				width: span(19 of 27);
				@include breakpoint($tablet) {
					width: span(9 of 13);
				}
			}
			&.r-prev, &.r-next {
				cursor: pointer;
				
				svg {
					fill: $white;
				}
			}
			&.r-next {
				margin-right: 0 !important;
			}
		}
	}

	.r-weekdays {
		color: $blue;
		font-size: 9px;
		line-height: 9px;
	}
	&:nth-of-type(n+2) {
		.r-weekdays {
			display: none;
		}
	}

	.r-dates {
		padding: 0;
		font-size: 11px;
		color: $darkgrey;
		line-height: 11px;
		@include border-radius(50%);

		.r-cell {
			@include maintain-aspect-ratio(1, 1);
			background-color: transparent;
			@include border-radius(50%);

			div {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				@include border-radius(50%);
				display: flex;
				justify-content: center;
				flex-direction: column;
			}
		}
	}
}
