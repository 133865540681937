.product {
	.content {
		display: grid;
		grid-gap: 20px;
		margin-top: 10px;
		padding: 20px 10px;
		background-color: $white;
		@include breakpoint($tablet) {
			grid-template-columns: repeat(2, 1fr);
			margin-top: 0;
			padding: 40px 20px;
		}
		
		.images {
			figure {
				@include maintain-aspect-ratio(16, 9);
			}
			.thumbs .thumb {
				@include maintain-aspect-ratio(16, 9);
				width: calc(25% - 5px);
				
				img {
					display: block;
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
		}
		.summary {
			display: grid;
			grid-template: 1fr auto / 1fr 1fr;
			@include breakpoint($tablet) {
				grid-template-columns: 2fr 1fr;
			}
			
			.pricing {
				margin-bottom: 20px;
				font-size: 14px;
				line-height: 30px;
				
				div {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-column-gap: 15px;
					
					span {
						text-align: right;
					}
					label {
						font-size: 12px;
						color: $blue;
					}
					
					&.fee {
						border-top: 1px solid $lightgrey;
					}
				}
			}
			.quantitySelect {
				grid-column: span 2;
				position: relative;
				display: grid;
				grid-template-columns: 1fr 2fr;
				grid-column-gap: 10px;
				
				input[type="text"] {
					text-align: center;
				}
				.info {
					position: absolute;
					top: 8px;
					left: calc(#{span(1 of 3)} - 35px)
				}
				a.btn {
					background-color: $green;
				}
			}
		}
		.details {
			margin-top: 9px;
			padding-top: 20px;
			border-top: 1px solid $lightgrey;
			
			& > div {
				display: grid;
				grid-template: auto 1fr / 2fr 3fr;
				margin-bottom: 15px;
				@include breakpoint($tablet) {
					grid-template-columns: 1fr 2fr;
				}
				
				figure {
					align-self: start;
					@include maintain-aspect-ratio(16, 9);
					grid-row: span 2;
					margin-right: 20px;
				}
			}
		}
		.misc {
			.dateRange {
				display: grid;
				padding-bottom: 20px;
				border-bottom: 1px solid $lightgrey;
				
				p {
					&:before, &:after {
						background-color: $lightgrey;
					}
				}
			}
			.dimensions {
				display: grid;
				grid-row-gap: 5px;
				margin-top: 20px;
				@include breakpoint($tablet) {
					grid-template-columns: 1fr 1fr;
				}
				
				a {
					text-decoration: underline;
				}
				& > div {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					line-height: 18px;
					@include breakpoint($tablet) {
						grid-column: 2;
					}
					
					label {
						color: $blue;
					}
				}
			}
			.company {
				display: grid;
				grid-template-columns: 4fr 6fr;
				grid-gap: 20px;
				padding-top: 20px;
				margin-top: 20px;
				border-top: 1px solid $lightgrey;
				
				figure {
					@include maintain-aspect-ratio(16, 9);
				}
				.information {
					a {
						display: inline-block;
						margin-bottom: 5px;
						line-height: 20px;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.suggestionGrid {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;

	thead {
		tr {
			th {
				padding: 5px;
				color: $blue;
			}
		}
	}
	tbody {
		tr {
			td {
				padding: 5px;
				border-top: 1px solid $lightgrey;
				color: $darkblue;
				vertical-align: top;
			}
		}
	}
}