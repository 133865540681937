.home {
	header {
		margin: 180px 0 0;
		@include breakpoint($tablet) {
			margin: 60px 0 200px;
		}
		
		.searchFilter {
			padding: 0 10px;
			@include breakpoint($tablet) {
				padding: 0;
			}
		}
		.headerLine {
			padding: 40px 20px;
			margin: -20px 0 10px;
			background-color: $blue;
			color: $white;
			opacity: 0.95;
			@include breakpoint($tablet) {
				grid-column: 2 / span 2;
				grid-row: 1;
				place-self: end stretch;
				padding: 0;
				margin: 0;
				background-color: transparent;
				opacity: 1;
				z-index: 1;
			}
			
			& > * {
				@include breakpoint($tablet) {
					display: inline-block;
					margin: 0;
					vertical-align: middle;
				}
			}
			h1 {
				@include breakpoint($tablet) {
					padding-right: 30px;
					border-right: 2px solid $white;
				}
			}
			p {
				@include breakpoint($tablet) {
					width: 220px;
					padding-left: 30px;
				}
			}
		}
	}
}

.homeCarousel {
	position: relative;
	
	.carousel {
		height: 100%;
		
		.slider-wrapper {
			height: 100%;
			
			.slider {
				height: 100%;
				
				.slide {
					height: 100%;
					
					img {
						display: block;
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
		.control-dots {
			bottom: 110px;
			z-index: 15;
			@include breakpoint($tablet) {
				bottom: 0;
			}
		}
	}
	
	&:after {
		content: " ";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		@include bkgd-gradient(180deg, transparent, rgba(7,24,39,0.4));
	}
}