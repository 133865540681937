.__react_component_tooltip {
	left: 0;
	width: 100%;
	padding: 20px;
	background-color: $blue;
	@include border-radius(5px);
	font-size: 12px;
	color: $white;
	white-space: normal;
	text-align: left;
	@include breakpoint($tablet) {
		left:-999em;
		width: 300px;
	}

	h3 {
		margin-bottom: 5px;
		color: $white;
	}
	
	@include breakpoint($phone $tablet) {
		&:after {
			border: none !important;
		}
	}
	 
	&.show {
		opacity: 1;
	}
	
	&.type-dark {
		background-color: $darkblue;
		 
		&.place-top:after {
			border-top: 6px solid;
			border-top-color: $darkblue;
		}
		&.place-bottom:after {
			border-bottom: 6px solid;
			border-bottom-color: $darkblue;
		}
		&.place-left:after {
			border-left: 6px solid;
			border-left-color: $darkblue;
		}
		&.place-right:after {
			border-right: 6px solid;
			border-right-color: $darkblue;
		}
	}
	
	&.type-light {
		color: $white;
		background-color: $blue;
		 
		&.place-top:after {
			border-top: 6px solid;
			border-top-color: $blue;
		}
		&.place-bottom:after {
			border-bottom: 6px solid;
			border-bottom-color: $blue;
		}
		&.place-left:after {
			border-left: 6px solid;
			border-left-color: $blue;
		}
		&.place-right:after {
			border-right: 6px solid;
			border-right-color: $blue;
		}
	}
	
	&.border {
		border:1px solid $white;
		
		&.place-top:before {
			border-top:8px solid $white;
		}
		&.place-bottom:before {
			border-bottom:8px solid $white;
		}
		&.place-left:before {
			border-left:8px solid $white;
		}
		&.place-right:before {
			border-right:8px solid $white;
		}
	}
}