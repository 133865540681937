
%arrows {
	@include transition(all, .25s, ease-in);
	@include opacity(.4);
	position: absolute;
	z-index: 2;
	top: 20px;
	background: none;
	border: 0;
	font-size: 32px;
	cursor: pointer;

	&:hover {
		@include opacity(1);
	}

	&.control-arrow {
		&:before {
			margin: 0 5px;
			display: inline-block;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			content: '';
		}
	}

	&.control-disabled {
		@include opacity(0);
		cursor: inherit;
		display: none;
	}

	&.control-prev {
		left: 0;
		&:before {
			border-right: 8px solid #fff;
		}
	}

	&.control-next {
		right: 0;
		&:before {
			border-left: 8px solid #fff;
		}
	}
}

.carousel {
    position: relative;
    width: 100%;

	// only applying box sizing inside the plugin so it won't break any style
	* {
        margin: 0;
        padding: 0;
		-webkit-box-sizing: border-box;
	  	-moz-box-sizing: border-box;
	  	box-sizing: border-box;
	}

    button {
        outline: 0;
        border: 0;
        background: none;
    }

	img {
		width: 100%;
		display: inline-block;
	}

	.carousel {
		position: relative;
	}

	.control-arrow {
		@extend %arrows;
		top: 50%;
		margin-top: -13px;
		font-size: 18px;
	}

	.thumbs-wrapper {
		margin: 20px 0 0;
		overflow: hidden;
	}

	.thumbs {
		@include transition(all, .15s, ease-in);
		// activate hardware accelerated animations using translate property
		position: relative;
		list-style: none;
		white-space: nowrap;
	}

	.thumb {
		@include transition(border, .15s, ease-in);
		display: inline-block;
		width: 80px;
		margin-right: 5px;
		white-space: nowrap;
		overflow: hidden;
		border: 2px solid $white;

		&.selected, &:hover {
			border-color: $blue;
		}
		&:last-of-type {
			margin-right: 0;
		}

		img {
			vertical-align: top;
		}
	}

	&.carousel-slider {
		position: relative;
		margin: 0;
		overflow: hidden;

		// adjusting the arrows for the slider
		.control-arrow {
			@extend %arrows;
			top: 0;
			color: #fff;
			font-size: 26px;
			bottom: 0;
			margin-top: 0;
			padding: 5px;

			&:hover {
				background: rgba(#000, .2);
			}

		}
	}

	.slider-wrapper {
		overflow: hidden;
		margin: auto;
		width: 100%;
        @include transition(height, .15s, ease-in);

		&.axis-horizontal {
			.slider {
				-ms-box-orient: horizontal;
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -moz-flex;
				display: -webkit-flex;
				display: flex;

				.slide {
					flex-direction: column;
					flex-flow: column;
				}
			}
		}

		&.axis-vertical {
			-ms-box-orient: horizontal;
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -moz-flex;
			display: -webkit-flex;
			display: flex;

			.slider {
				-webkit-flex-direction: column;
				flex-direction: column;
			}
		}
	}

	.slider {
		position: relative;
		list-style: none;
		width: 100%;

		&.animated {
			@include transition(all, .35s, ease-in-out);
			// activate hardware accelerated animations using translate property
			// @include enhanceAnimations();
		}
	}


	.slide {
		min-width: 100%;
		margin: 0;
		position: relative;
		text-align: center;
		background: #000;
		// padding-bottom: 32px;

		img {
			width: 100%;
			vertical-align: top;
			border: 0;
		}

		iframe {
			display: inline-block;
			width: calc(100% - 80px);
			margin: 0 40px 40px;
			border: 0;
		}

		.legend {
			@include transition(all, .5s, ease-in-out);
			position: absolute;
			bottom: 40px;
			left: 50%;
			margin-left: -45%;
			width: 90%;
			@include border-radius(10px);
			background: #000;
			color: #fff;
			padding: 10px;
			font-size: 12px;
			text-align: center;
			opacity: 0.25;
			@include transition(opacity, .35s, ease-in-out);
		}
	}

	.control-dots {
		position: absolute;
		bottom: 0;
		margin: 10px 0;
		text-align: center;
		width: 100%;



		.dot {
			@include transition(opacity, .25s, ease-in);
			opacity: 0.3;
			background: $lightgrey;
			@include border-radius(50%);
			width: 8px;
			height: 8px;
			cursor: pointer;
			display: inline-block;
			margin: 0 8px;

			&.selected, &:hover {
				opacity: 1;
			}
		}
	}

	.carousel-status {
		position: absolute;
		top: 0;
		right: 0;
		padding: 5px;
		font-size: 10px;
		text-shadow: 1px 1px 1px rgba(#000, .9);
		color: #fff;
	}

	&:hover {
		.slide .legend {
		 	opacity: 1;
		}
	}
}
