.rc-time-picker {
	display: inline-block;
	box-sizing: border-box;
	
	* {
		box-sizing: border-box;
	}

	.rc-time-picker-input {
		position: relative;
		display: inline-block;
		width: 100%;
		cursor: text;
		font-size: 16px;
		line-height: 1.5;
		color: $darkblue;
		transition: border .2s cubic-bezier(0.645, 0.045, 0.355, 1), background .2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow .2s cubic-bezier(0.645, 0.045, 0.355, 1);
		@include breakpoint($tablet) {
			font-size: 12px;
		}
	}
	
	&.disabled {
		opacity: 0.4;
		.rc-time-picker-input {
			cursor: not-allowed;
		}
	}
}

.rc-time-picker-panel {
	position: absolute;
	width: 170px;
	padding-top: 3px;
	box-sizing: border-box;
	z-index: 1070;
}
.rc-time-picker-panel-inner {
	display: inline-block;
	position: relative;
	outline: none;
	list-style: none;
	font-size: 16px;
	text-align: left;
	background-color: $white;
	border-radius: 2px;
	background-clip: padding-box;
	border: 1px solid $lightgrey;
	line-height: 1.5;
	@include breakpoint($tablet) {
		font-size: 12px;
	}
}
.rc-time-picker-panel-input {
	margin: 0;
	padding: 0 10px;
	height: 35px;
	width: 100%;
	border: 1px solid transparent;
	outline: 0;
	line-height: 35px;
	cursor: auto;
	@include breakpoint($tablet) {
		height: 30px;
		font-size: 12px;
		line-height: 30px;
	}
}

.rc-time-picker-panel-input-wrap {
	position: relative;
	border-bottom: 1px solid $lightgrey;
	overflow: auto;
	
	.rc-time-picker-panel-input {
		padding: 0 8px;
		margin-top: -1px;
	}
}

.rc-time-picker-panel-input-invalid {
	border-color: $red;
}

.rc-time-picker-panel-clear-btn {
	position: absolute;
	right: 6px;
	cursor: pointer;
	overflow: hidden;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	top: 6px;
	margin: 0;

	&:after {
		content: "x";
		font-size: 12px;
		color: $darkgrey;
		display: inline-block;
		line-height: 1;
		width: 20px;
		transition: color 0.3s ease;
	}
	&:hover:after {
		color: $darkblue;
	}
}

.rc-time-picker-panel-select {
	float: left;
	font-size: 16px;
	border: 1px solid $lightgrey;
	border-width: 0 1px;
	margin-left: -1px;
	width: 56px;
	overflow: hidden;
	position: relative; // Fix chrome weird render bug
	@include breakpoint($tablet) {
		font-size: 12px;
	}

	&:first-child {
		border-left: 0;
		margin-left: 0;
	}

	&:last-child {
		border-right: 0;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
		max-height: 144px;
	}

	li {
		position: relative;
		list-style: none;
		box-sizing: content-box;
		margin: 0;
		padding: 0 0 0 16px;
		width: 100%;
		height: 24px;
		line-height: 24px;
		text-align: left;
		cursor: pointer;
		user-select: none;
		z-index: 0;
		
		&:before {
			content: " ";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: -1;
			opacity: 0.2;
		}

		&:hover:before {
			background-color: $blue;
		}
		&.rc-time-picker-panel-select-option-selected {
			color: $blue;
			
			&:before {
				background-color: $blue;
			}
		}
		&.rc-time-picker-panel-select-option-disabled {
			color: $darkgrey;
			
			&:hover {
				background: transparent;
				cursor: not-allowed;
					
				&:before {
					background-color: $blue;
				}
			}
		}
	}
}

.rc-time-picker-panel-select-active {
	overflow-y: auto;
}

@media (pointer:coarse) {  
  .rc-time-picker-panel {
  	.rc-time-picker-panel-select {
  		max-height: 144px;
  		overflow-y: auto;
  		ul {
  			max-height: initial;
  		}
  	}
  }
}
