.notifications-wrapper {
}

.notifications-tr, .notifications-tl, .notifications-tc, 
.notifications-br, .notifications-bl, .notifications-bc {
	position: fixed;
	height: auto;
	width: 320px;
	padding: 0 10px 10px;
	z-index: 9998;
}
.notifications-tr {
	top: 0;
	bottom: auto;
	left: auto;
	right: 0;
}
.notifications-tl {
	top: 0;
	bottom: auto;
	left: 0;
	right: auto;
}
.notifications-tc {
	margin-left: -160px;
	top: 0;
	bottom: auto;
	left: 50%;
	right: auto;
}
.notifications-br {
	top: auto;
	bottom: 0;
	left: auto;
	right: 0;
}
.notifications-bl {
	top: auto;
	bottom: 0;
	left: 0;
	right: auto;
}
.notifications-bc {
	margin-left: -160px;
	top: auto;
	bottom: 0;
	left: 50%;
	right: auto;
}

.notification {
	position: relative;
	display: block;
	width: 100%;
	padding: 10px 10px 20px;
	border-radius: 2px;
	font-size: 12px;
	opacity: 0;
	transition: all 0.3s ease-in-out 0s;
	transform: translate3d(0, 100%, 0);
	will-change: transform, opacity;
	cursor: pointer;

	&.notification-hidden  {
		transform: translate3d(0, 100%, 0);
		opacity: 0;
	}
	&.notification-visible  {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	&.notification-success {
		background-color: $blue;
		color: $white;
	}
	&.notification-info {
	}
	&.notification-warning {
	}
	&.notification-error {
		background-color: $red;
		color: $white;
	}

	&.notification-not-dismissible {
	}
}

.notification-title {
	padding: 0;
	margin: 0 0 15px;
	font-size: 12px;
	line-height: 15px;
	font-weight: $bold;
	color: $white;
}

.notification-message {
	padding: 0;
	margin: 0;
	
	ul {
		padding-left: 20px;
		list-style: outside;
	}
}

.notification-dismiss {
	position: absolute;
	top: 9px;
	right: 5px;
	height: 14px;
	width: 14px;
	font-family: Arial;
	font-size: 17px;
	font-weight: $bold;
	line-height: 15px;
	text-align: center;
	cursor: pointer;
}

.notification-action-wrapper {
	padding: 0;
	margin: 0;
}

.notification-action-button {
	padding: 6px 20px;
	margin: 10px 0 0 0;
	background-color: $white;
	border: 0;
	border-radius: 2px;
	font-weight: $bold;
}