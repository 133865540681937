.rental {
	position: relative;
	
	header {
		.details {
			grid-column: 1;
			grid-row: 1;
			color: $white;
			
			h1 {
				margin: 0;
			}
			h3 {
				color: $white;
			}
		}
		.btn {
			width: auto;
			margin: 0 0 10px;
			
			&.outline {
				border-color: $white;
				color: $white;
				
				svg {
					fill: $white;
				}
			}
			&:nth-of-type(2) {
				margin-left: 5px;
			}
		}
		.right {
			grid-row: 1;
			grid-column: 3/ span 2;
			place-self: center end;
		}
	}
	
	.actions {
		margin-top: 10px;
	}
	
	& > div {
		display: grid;
		@include breakpoint($tablet) {
			grid-template: auto / repeat(12, 1fr);
		}
	}
	
	.items {
		grid-row: 2;
		position: relative;
		padding: 10px 20px 20px;
		margin-bottom: 20px;
		background-color: $white;
		@include breakpoint($tablet) {
			grid-column: 1 / span 9;
			grid-row: 1;
		}
		
		h3 {
			line-height: 40px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			
			span, label {
				margin-left: 5px;
			}
			span {
				color: $blue;
			}
			label {
				font-size: 12px;
				font-weight: $regular;
				line-height: 40px;
				color: $darkgrey;
			}
		}
		h4 {
			color: $blue;
		}
		& > div {
			margin-bottom: 20px;
		}
		.grid {
			tbody {
				&:first-of-type tr:first-of-type td {
					border-top: 2px solid $blue;
				}
				td:not(.image) {
					height: 50px;
					
					&.image {
						height: auto;
					}
				}
				
				&.itemContent {
					.bottom td:not(.image) {
						vertical-align: bottom;
					}
				}
				&.mobileItemContent {
					figure {
						@include maintain-aspect-ratio(16,9);
						margin-top: 5px;
					}
					td tr:not(.noBorder) td {
						border-top: 1px solid $lightgrey !important;
					}
				}
			}
      .normalText {
        white-space: normal;
      }
			.caption {
				color: $darkgrey;
				white-space: inherit;
			}
			.indent {
				border-top: none;
				
				td {
					border-top: 1px solid $lightgrey !important;
				}
			}
		}
		.subtotal {
			border-top: 2px solid $darkblue;
			line-height: 30px;
			text-align: right;
			
			strong {
				margin-right: 5px;
			}
		}
	
		&:before {
			content: " ";
			position: absolute;
			top: 10px;
			left: -5px;
			height: 40px;
			width: 5px;
			background-color: $blue;
		}
	}
	.summary {
		grid-row: 1;
		align-self: start;
		display: grid;
		grid-template-columns: 3fr 1fr;
		padding: 10px 20px 20px;
		background-color: $darkblue;
		color: $white;
		@include breakpoint($tablet) {
			grid-column: 10 / span 3;
			grid-row: 1;
			margin-bottom: 20px;
		}
		
		h4 {
			margin: 10px 0 30px;
			line-height: 20px;
			color: $blue;
			
			span {
				display: block;
				color: $white;
			}
		}
		a:not(.btn) {
			place-self: start end;
			position: relative;
			line-height: 40px;
			
			svg {
				height: 21px;
				width: 23px;
				fill: $blue;
				vertical-align: middle;
			}
			label {
				position: absolute;
				top: 12px;
				left: 0;
				width: 23px;
				font-size: 9px;
				line-height: 13px;
				color: $white;
				text-align: center;
			}
		}
		.billingDetails, .actions, .term, .recurringPaymentDetails {
			grid-column: 1 / span 2;
		}
		.term {
			margin-top: 10px;
			color: $white;
			text-align: center;
		}
	}
	.delivery, .venue, .documents, .notes, .billing, .payments {
		padding-bottom: 20px;
		margin: 0 20px 20px;
		border-bottom: 1px solid $blue;
		@include breakpoint($tablet) {
			padding-bottom: 0;
			margin: 0 0 20px;
			border-bottom: none;
		}
		
		h4 {
			color: $blue;
		}
		&:last-of-type() {
			border-bottom: none;
		}
	}
	.delivery {
		@include breakpoint($tablet) {
			grid-column: 1 / span 4;
			grid-row: 2;
			padding-right: 20px;
		}
		
		.btnLink {
			float: right;
			line-height: 40px;
		}
		.details {
			display: grid;
			grid-template: auto / repeat(2, 1fr);
			grid-row-gap: 20px;
			
			label {
				display: block;
				font-weight: $bold;
			}
			
			& > div:nth-of-type(3) {
				grid-column: span 2;
			}
		}
		.form {
			margin-bottom: 20px;
			
			input[type="text"], select, .react-datepicker-input {
				background-color: $white;
			}
		}
	}
	.venue {
		@include breakpoint($tablet) {
			grid-column: 1 / span 4;
		}
		
		& + .documents {
			grid-row: 4;
		}
	}
	.documents {
		@include breakpoint($tablet) {
			grid-column: 1 / span 4;
			grid-row: 3;
			padding-right: 20px;
		}
		
		a {
			display: block;
			line-height: 30px;
			color: $darkblue;
			
			svg {
				width: 13px;
				height: 20px;
				margin-right: 5px;
				fill: $blue;
				vertical-align: middle;
			}
		}
	}
	.notes {
		@include breakpoint($tablet) {
			grid-column: 1 / span 4;
		}

		.additionalHeader {
			margin-bottom: 0;
		}
		
		& > div {
			padding: 5px 0;
			border-top: 1px solid $meddarkgrey;
			
			span {
				font-size: 10px;
				color: $blue;
			}
		}
	}
	.billing {
		@include breakpoint($tablet) {
			grid-column: 5 / span 4;
			grid-row: 2 / span 4;
			padding-right: 20px;
		}
		
		.billingDetails {
			& > div {
				border-top: 1px solid $meddarkgrey;
				
				&.subtotal, &.total, &.total:before {
					border-top: 2px solid $darkblue;
				}
			}
		}
	}
	.payments {
		@include breakpoint($tablet) {
			grid-column: 9 / span 4;
			grid-row: 2 / span 4;
		}
		
		.billingDetails {
			& > div {
				border-top: 1px solid $meddarkgrey;
				
				&.subtotal {
					border-top: 2px solid $darkblue;
				}
			}
		}
	}
	
	&.guestCheckout {
		.summary {
			padding-top: 40px;
		}
	}
}

.changeRequestDetails {
  margin: 10px 0;

  h6 {
    span {
      &.adjusted-by {
        font-weight: bold;
        font-size: 12px;
        color: #000000;
        margin-bottom: 10px;
      }

      &:last-of-type {
        width: span(1 of 4);
        float: right;
      }
    }
  }
}


.recurring-data-tooltip-header {
  font-weight: normal;
}

.recurring-data-tooltip-table {
  width: 100%;

  tr {
    td {
      color: white;
    }
  }
}

.recurringPaymentDetails {
  margin: 25px 0px;

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    line-height: 20px;

    & > span {
      font-weight: $bold;
      text-align: right;
    }
  } 
}