	.discoverTheLook {
	.searchFilter {
		input[type="search"] {
			width: 100%;
			padding-left: 30px;
			@include border-radius(2px);
		}

		&:after {
			background-image: url(svg-encode(search-icon($blue)));
		}
	}
	.looks {
		display: grid;
		grid-gap: 10px;
		@include breakpoint($tablet) {
			grid-template-columns: repeat(3, 1fr);
		}

		.look {
			position: relative;
			@include breakpoint($tablet) {
				height: 360px;

				&:nth-of-type(1), &:nth-of-type(3) {
					height: 300px;
					margin-top: 0 !important;
				}
				&:nth-child(3n+1) {
					grid-column: 1;
					margin-top: -60px;
				}
				&:nth-child(3n+2) {
					grid-column: 2;

					&:last-of-type, &:nth-last-of-type(2) {
						height: 300px;
						margin-top: 0 !important;
					}
				}
				&:nth-child(3n+3) {
					grid-column: 3;
					margin-top: -60px;
				}

				&:hover {
					.details {
						visibility: visible;
					}
					.shareBtn, .favoriteBtn {
						visibility: visible;
					}
				}
			}

			figure {
				display: block;
				height: 100%;
				width: 100%;
			}

			.details {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				@include breakpoint($tablet) {
					visibility: hidden;
				}

				h3 {
					color: $white;
					z-index: 1;
				}
				.btn {
					width: 50%;
					z-index: 1;
				}
				&:after {
					content: " ";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: $navyblue;
					opacity: 0.2;
					z-index: 0;
				}
			}

			.shareBtn, .favoriteBtn {
				position: absolute;
				top: 5px;
				height: 18px;
				width: 18px;
				background-color: $white;
				@include border-radius(50%);
				text-align: center;
				z-index: 1;
				@include breakpoint($tablet) {
					visibility: hidden;
				}
			}
			.shareBtn {
				right: 28px;

				svg {
					height: 15px;
					width: 13px;
					fill: $blue;
				}
			}
			.favoriteBtn {
				right: 5px;

				svg {
					height: 14px;
					width: 14px;
					fill: $darkgrey;
				}

				&.active {
					@include breakpoint($tablet) {
						visibility: visible;
					}

					svg {
						fill: $red;
					}
				}
			}
			.tooltip {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				width: 230px;
				padding: 5px 10px 0;
				background-color: $white;
				color: $darkblue;
				text-align: center;
				pointer-events: auto;

				&:after {
					border-left-color: $white;
				}

				p {
					grid-column: span 3;
				}
				div {
					position: relative;
					margin-bottom: 10px;
					font-size: 10px;
					line-height: 14px;
					cursor: pointer;

					svg {
						display: block;
						height: 20px;
						width: 20px;
						margin: 5px auto 10px;
						fill: $white;
					}
					&:after {
						content: " ";
						position: absolute;
						top: 0;
						left: 50%;
						height: 30px;
						width: 30px;
						transform: translate(-50%, 0);
						background-color: $blue;
						@include border-radius(50%);
						z-index: -1;
					}
				}
			}
		}
	}
	.pagerWrapper .pager {
		margin: 0 auto;
	}
}

.discoverSignInModal {
	h2 {
	}
	h3 {
		color: $blue;
	}
	.form + p {
		margin-top: 10px;
		text-align: center;

		a {
			text-decoration: underline;
		}
	}
}

.getTheLook {
	header {
		@include breakpoint($tablet) {
			display: flex !important;
			padding: 15px 0;
		}

		.btn {
			width: 100px;
			margin: 0 10px 0 0;

			&.outline {
				width: 255px;
				margin: 0 0 0 auto;
				border-color: $white;
				color: $white;
			}
		}
		h3 {
			color: $white;

			a {
				margin-left: 10px;
				font-size: 10px;
				color: $white;
			}
		}
	}
	.lookCarousel {
		position: relative;
		height: 300px;
		@include breakpoint($tablet) {
			height: 420px;
		}

		.carousel {
			height: 100%;
			background-color: $darkgrey;

			.slider-wrapper {
				height: 300px;
				@include breakpoint($tablet) {
					height: 420px;
				}

				.slider {
					height: 100%;

					.slide {
						height: 100%;

						img {
							display: block;
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}
				}
			}

			.control-arrow {
				top: 50%;
				transform: translate(0, -50%);
				height: 30px;
				width: 30px;
				@include border-radius(50%);
				background-color: $white;
				opacity: 1;
				@include breakpoint($tablet) {
					opacity: 0.7;

					&:hover {
						opacity: 1;
					}
				}

				&:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					height: 12px;
					width: 8px;
					margin: 0;
					border: none;
					background-position: left top;
					background-size: 8px 12px;
				}

				&.control-prev {
					left: 10px;

					&:before {
						background-image: url(svg-encode(left-arrow($darkblue)));
					}
				}
				&.control-next {
					right: 10px;

					&:before {
						background-image: url(svg-encode(right-arrow($darkblue)));
					}
				}
			}
		}
	}
	.photoCredits {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@include breakpoint($tablet) {
			height: 40px;
		}

		div {
			margin: 0 10px;
			font-size: 9px;
			line-height: 40px;

			label {
				font-weight: $bold;
			}
		}
	}
	& > p {
		margin: 0 0 30px;
		@include breakpoint($tablet) {
			max-width: 680px;
			margin: 10px auto 30px;
		}
	}
	.storeItems {
		@include breakpoint($tablet) {
			max-width: 680px;
			margin: 0 auto;
		}
	}
}
