#root {
	position: relative;
	
	// footer background
	@include breakpoint($tablet) {
		&:after {
			content: " ";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 400px;
			background-color: $navyblue;
			z-index: -1;
		}
	}
}

.container {
	display: grid;
	min-height: 100vh;
	grid-template: 180px 220px 1fr auto / 1fr;
	@include breakpoint($tablet) {
		grid-template: 400px 1fr auto / 1fr minmax(auto, 1020px) 1fr;
	}
	
	& > aside {
		grid-row: 4;
		@include breakpoint($tablet) {
			grid-column: 2;
			grid-row: 3;
		}
	}
	& > header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 40px;
		background-color: $white;
		z-index: 30;
		@include breakpoint($tablet) {
			height: 40px;
		}
	}
	& > main {
		grid-column: 1;
		grid-row: 1 / span 3;
		padding: 40px 10px 20px;
		@include breakpoint($tablet) {
			grid-column: 2;
			grid-row: 1 / span 2;
			padding: 40px 0 60px;
		}
		
		header {
			display: grid;
			grid-template: 110px / 1fr;
			align-items: center;
			@include breakpoint($tablet) {
				grid-template: 160px / repeat(4, 1fr);
			}
			
			h1 {
				grid-area: 1 / 1;
			}
			.linkBack {
				grid-area: 1 / 1;
				place-self: end start;
			}
			.searchFilter, .progress {
				grid-column: 1;
				grid-row: 1;
				place-self: end stretch;
				@include breakpoint($tablet) {
					grid-column: 2 / span 2;
					place-self: center stretch;
				}
			}
		}
	}
	& > footer {
		display: grid;
		grid-column: 1;
		grid-row: 4;
		height: 420px;
		padding: 60px 40px;
		background-color: $navyblue;
		@include breakpoint($tablet) {
			grid-column: 2;
			grid-row: 3;
			grid-template: 1fr / repeat(3, 1fr);
			height: 400px;
			padding: 80px 0 0;
		}
		
		div {
			font-size: 11px;
			color: $white;
			
			svg {
				width: 30px;
				height: 15px;
				margin-bottom: 30px;
				fill: $blue;
			}
			h3 {
				margin-bottom: 15px;
				color: $blue;
			}
			nav {
				display: flex;
				flex-direction: column;
				
				a {
					line-height: 30px;
					color: $white;
				}
			}
		}
			
		.nav {
			grid-column: 1;
			grid-row: 3;
			@include breakpoint($tablet) {
				grid-column: 1;
				grid-row: 1;
			}
		}
		.marketing {
			grid-column: 1;
			grid-row: 2;
			padding-bottom: 30px;
			@include breakpoint($tablet) {
				grid-column: 2;
				grid-row: 1;
				padding-bottom: 0;
			}
		}
		.socialLinks {
			visibility: hidden;
			grid-column: 1;
			grid-row: 1;
			@include breakpoint($tablet) {
				grid-column: 3;
				grid-row: 1;
			}
			
			svg {
				width: 20px;
				height: 20px;
				margin-right: 20px;
			}
		}
	}
	
	.homeCarousel {
		grid-column: 1;
		grid-row: 1 / span 2;
		margin-top: 40px;
		@include breakpoint($tablet) {
			grid-column: 1 / span 3;
			grid-row: 1;
		}
	}
	.categories {
		grid-column: 1;
		grid-row: 4;
		margin-top: -20px;
		@include breakpoint($tablet) {
			grid-column: 1 / span 3;
			grid-row: 3;
			margin-top: -60px;
		}
		
		& + footer {
			grid-row: 5;
			@include breakpoint($tablet) {
				grid-row: 4;
			}
		}
	}
	
	// header background gradient
	&:before {
		content: " ";
		grid-column: 1;
		grid-row: 1 / span 2;
		margin-top: 40px;
		z-index: -1;
		@include breakpoint($tablet) {
			grid-column: 1 / span 3;
			grid-row: 1;
		}
	}
	// header background image
	&:after {
		content: " ";
		grid-column: 1;
		grid-row: 1 / span 2;
		background: transparent image("marketing/pattern.png") repeat-x center center;
		z-index: -1;
		@include breakpoint($tablet) {
			grid-column: 1 / span 3;
			grid-row: 1;
		}
	}
	
	&.morning:before {
		background: linear-gradient(177deg, #D8653D 0%, #625FC1 50%, $blue 100%);
	}
	&.afternoon:before {
		background: linear-gradient(177deg, #D8BD5D 0%, #25AFBC 50%, $blue 100%);
	}
	&.evening:before {
		background: linear-gradient(177deg, #4A037B 0%, #1D5497 50%, $blue 100%);
	}
}

.errorLanding {
	display: grid;
	margin-top: 110px;
	@include breakpoint($tablet) {
		grid-template-columns: repeat(3, 1fr);
		margin-top: 160px;
	}
	
	section {
		padding: 20px 40px 80px;
		background-color: $white;
		@include breakpoint($tablet) {
			grid-column: 2;
		}
		
		h1 {
			color: $darkblue;
		}
		p {
			width: 200px;
			margin: 40px 0;
		}
		.btn {
			width: 100%;
		}
	}
}
.notSupported {
	min-height: 100vh;
	padding-top: 200px;
	background: $white image("marketing/bkgd_NotFound.png") no-repeat center top;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	
	svg {
		width: 70px;
		height: 54px;
		margin-bottom: 10px;
	}
	h1 {
		margin-bottom: 20px;
		font-size: 36px;
		font-weight: $bold;
		line-height: 40px;
		color: $darkblue;
	}
}

.noMobile {
	display: none !important;
	@include breakpoint($tablet) {
		display: inherit !important;
	}
}
.noDesktop {
	display: inherit !important;
	@include breakpoint($tablet) {
		display: none !important;
	}
}