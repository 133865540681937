.signIn, .signUp, .forgotPassword, .passwordReset, .reclaim {
	section {
		padding: 30px 10px 40px;
		background-color: $white;
		@include breakpoint($tablet) {
			padding: 40px 40px 60px;
			margin: 0 span(1 of 3) 40px;
		}
	}
	
	.actions {
		margin-bottom: 10px;
	}
	a:not(.btn) {
		display: block;
		text-decoration: underline;
		font-size: 12px;
		text-align: center;
	}
	
	.reclaimForm {
		p {
		}
	}
}
.reclaim {
	section {
		.form, p {
			margin-bottom: 15px;
		}
	}
}

.signInModal {
	@include breakpoint($tablet) {
		height: 400px;
		width: 600px;
	}
	
	header h4 {
		position: relative;
		@include breakpoint($tablet) {
			color: $white;
		}
	}
	main > div {
		display: grid;
		@include breakpoint($tablet) {
			grid-template-columns: 1fr 1fr;
		}
		
		.icon {
			display: none;
			height: 400px;
			margin: -40px 0 -20px -20px;
			background-color: $blue;
			@include breakpoint($tablet) {
				display: block;
			}
			
			svg {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
				width: 207px;
				height: 283px;
				fill: $white;
			}
		}
		.details {
			place-self: center stretch;
			@include breakpoint($tablet) {
				padding-left: 20px;
			}
			
			.form {
				margin-bottom: 20px;
			}
			p {
				margin-bottom: 20px;
				font-size: 16px;
			}
			a {
				display: block;
				text-align: center;
				text-decoration: underline;
			}
		}
	}
}