.mainMenu .araLogo {
	height: 67px;
	width: 340px;
	background: transparent url('../../../images/ara/logo.png') no-repeat left center;
  margin: 71px 0 38px;
}

.araHome {
	header {
		grid-template-rows: auto !important;
		margin: 180px 0 0;

		.searchFilter {
      padding: 0 10px;
      margin-top: 130px;
      margin-bottom: 50px;

			input[type="search"] {
				width: 100%;
				padding: 0 10px 0 30px;
				background-image: url(svg-encode(search-icon($blue)));
				@include border-radius(2px);
				margin: 0;
			}
			ul {
				left: 0;
			}
			&:after {
				display: none;
			}
		}
		.headerLine {
			padding: 40px 20px;
			margin: -20px 0 10px;
			color: $blue;
			opacity: 0.95;
			@include breakpoint($tablet) {
				grid-column: 2 / span 2;
				place-self: end stretch;
				padding: 0;
				margin: 0;
				background-color: transparent;
				opacity: 1;
				z-index: 1;
			}

			& > * {
				@include breakpoint($tablet) {
					display: inline-block;
					margin: 0;
					vertical-align: middle;
				}
			}
			h1 {
				@include breakpoint($tablet) {
					font-size: 24px;
					line-height: 40px;
				}
			}
		}
	}
}
.araCategories {
  font-size: 14px;
	.large {
		padding: 40px 0 70px;
    background-color: white;
		h3, ul {
			@include center-column;
		}
		h3 {
      margin-bottom: 52px;
      margin-top: 45px;
			font-size: 22px;
			font-weight: $bold;
			line-height: 24px;
			color: $darkblue;
      text-align: center;

		}
		ul {
			display: grid;
			grid-gap: 1px;
			@include breakpoint($tablet) {
				grid-template-columns: repeat(4, 1fr);
			}

			li {

				a {
					display: flex;
          align-items: center;
          flex-direction: column;
					font-weight: $bold;
					color: $blue;

					img {
						height: 200px;
					}
					label {
						position: relative;
						margin-left: 35px;
					}
				}
      }
      li:nth-child(2) {
        margin-top: 10px;
      }
		}
	}
	.small {
		padding: 50px 0;
		background-color: #0058A2;

		h3, ul {
			@include center-column;
		}
		h3 {
			margin-bottom: 15px;
			font-size: 22px;
			font-weight: $bold;
			line-height: 24px;
			color: white;
			text-align: center;
    }
    h4 {
      font-size: 22px;
      font-weight: $bold;
      line-height: 24px;
      color: white;
      text-align: left;
    }
		ul {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 10px;
			@include breakpoint($tablet) {
				grid-template-columns: repeat(2, 1fr);
			}

			li {
        background-color: #0058A2;
        height: 308px;
        border: 3px solid $darkblue;

				a {
					display: flex;
					flex-direction: column;
					align-items: center;
          width: 185px;
          margin-left: 46.5px;
          background-color: $darkblue;
        }
        &:last-child a {
          margin-top: 28px;
        }
        img {
          height: 80px;
          width: 75px;
          margin: 60px 0 0 35px;

         }
        img:first-child {
          margin: 60px 0 0 46.5px;
        }
        p {
          line-height: 18px;
          font-size: 12px;
          color: white;
          margin-top: 15px;
        }
        label {
          color: white;
        }
      }

      li > * {
        margin-left: 46.5px;
      }
		}
	}

	svg {
		width: 100px;
		height: 100px;
  }

  .imageGrid {
    background-color: $darkblue;
    display: grid;
    height: 340px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3px;
    .first {
      grid-column: 1 / span 2;
      max-height: 340px;
      img {
        width: 100%;
      }
    }
    .second {
      grid-column: 3;
      max-height: 340px;
      img {
        width: 100%;
      }
    }
    .third{
      grid-column: 4;
      max-height: 168.5px;
			@include breakpoint($tablet) {
      height: 168.5px;
      }

      img {
        width: 100%;
      }
    }
    .last{
      margin-top: -2px;
    }
  }

}

.container .araCategories {
	grid-column: 1;
	grid-row: 4;
	margin-top: 265px;
	@include breakpoint($tablet) {
		grid-column: 1 / span 3;
		grid-row: 3;
    margin-top: 0px;
  }
  	& + footer {
		grid-row: 6;
		@include breakpoint($tablet) {
			grid-row: 5;
		}
	}
}

.container .araHeader {
    position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 151px;
    z-index: 30;
    background: linear-gradient($darkblue, $darkblue 27%, white 27%);
		@include breakpoint($tablet) {
      height: 151px;
      background: linear-gradient($darkblue, $darkblue 27%, white 27%);
    }
    .callouts {
      margin-right: -205px;
      margin-top: 30px;
    }
    .links {
      margin-top: 30px;
    };
}

	.araHomeCarousel {
		grid-column: 1;
		grid-row: 1 / span 2;
		margin-top: 151px;
		@include breakpoint($tablet) {
			grid-column: 1 / span 3;
      grid-row: 1;
		}
  }

  .araHomeCarousel {
  position: relative;
  margin-top: 151px;
  height: 100%;

	.carousel {
		height: 100%;

		.slider-wrapper {
			height: 100%;

			.slider {
				height: 100%;

				.slide {
					height: 100%;

					img {
						display: block;
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
		.control-dots {
			bottom: 110px;
			z-index: 15;
			@include breakpoint($tablet) {
				bottom: 0;
			}
		}
	}

	&:after {
		content: " ";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		@include bkgd-gradient(180deg, transparent, rgba(7,24,39,0.4));
	}
}
  .araHeader .links {
  margin-left: auto;
  align-items: center;
  a {
    display: inline-block;
    padding: 0 10px;
    margin-right: 10px;
    line-height: 40px;
    color: #868686;
    text-transform: uppercase;
    border-bottom: none;

    &.active, &:hover {
      color: $blue;
      border-bottom: none;
    }
  }
}

.araHeader .callouts {
		align-items: center;
		position: relative;

		.location {
			position: relative;
			width: 75px;
			padding-left: 20px;
			line-height: 40px;
			text-align: right;
			white-space: nowrap;

			svg {
				margin-left: 5px;
				fill: $navyblue;
				vertical-align: middle;
			}
			div {
				position: absolute;
				top: 100%;
				left: -30px;
				width: 280px;
				height: 70px;
				padding: 0 30px 20px;
				font-weight: $regular;
				white-space: nowrap;

				form {
					width: 220px;
					padding: 0 5px;
					background-color: $white;

					input[type="text"] {
						width: 50%;
						margin: 0 5px 0 0;
						background-color: $navyblue;
						color: $meddarkgrey;

						/* Override the color of the placeholder text */
						::-webkit-input-placeholder { /* WebKit, Blink, Edge */
							color: $meddarkgrey;
						}
						:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
							color: $meddarkgrey;
						}
						::-moz-placeholder { /* Mozilla Firefox 19+ */
							color: $meddarkgrey;
						}
						:-ms-input-placeholder { /* Internet Explorer 10-11 */
							color: $meddarkgrey;
						}
					}
					.btn {
						width: calc(50% - 5px);
						margin: 0;
					}
				}
			}
		}
		.icon {
			position: relative;
			padding-left: 20px;
			color: $white;
			text-align: center;

			svg {
				height: 23px;
				fill: $navyblue;
				vertical-align: middle;
			}
			label {
				position: absolute;
				top: 4px;
				width: 20px;
				font-size: 9px;
				line-height: 13px;
				color: $white;
			}

			&.active, &:hover {
			}

			&.message label {
				left: 22px;
			}
			&.cart label {
				left: 27px;
			}
			&.favorites label {
				left: 24px;
			}

			&:hover svg {
				fill: $blue;
			}
		}

		&:before {
      content: none;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			height: 50%;
			width: 1px;
			background-color: $darkblue;
		}
	}

.araHeader {
  .mainMenu .icon svg {
    fill: $blue;
  }
  .location img {
    margin-right: 2px;
    margin-bottom: -2px;
  }
  .location {
    border-left: 1px solid $darkblue;
  }

  .cart {
    border-left: 1px solid $darkblue;
    margin-left: 25px;
    padding: 10px;
  }
}
