.innerZoom {
    margin: 0;
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: zoom-in;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        pointer-events: none;
      }

      .innerZoomZoomedImage {
        width: auto !important;
        max-width: none !important;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        display: block;
    
        &.zoomedVisible{
            visibility: visible;
            opacity: 1;
            cursor: zoom-out;
        }
      }
    
      .innerZoomImagePortal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
      }
      
      .innerZoomBtn {
        background: rgba(255, 255, 255, 0.8);
        width: 40px;
        height: 40px;
        border: none;
        outline: none;
        padding: 0;
        position: absolute;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        appearance: none;
      }
      
      .innerZoomBtn:before {
        content: " ";
        background-position: center;
        background-repeat: no-repeat;
        display: block;
      }
      
      .innerZoomHint {
        bottom: 10px;
        right: 10px;
        pointer-events: none;
      }
      
      .innerZoomHint:before {
        content: " ";
        background-image: url(svg-encode(search-icon($blue)));
        width: 20px;
        height: 20px;
      }
  }
  
.thumb {
    img{
        display: inline !important;
    }
}