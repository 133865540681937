.events {
	display: grid;
	@include breakpoint($tablet) {
		grid-template-columns: 1fr 2fr;
	}
	
	.sideNav {
		align-self: start;
		@include breakpoint($tablet) {
			height: 250px;
		}
	}
	.items {
		padding: 40px 20px 20px;
		background-color: $white;
		
		.month {
			h4 {
				font-size: 12px;
			}
			.summary div {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 20px;
				
				span {
					font-weight: $bold;
					text-align: right;
					@include breakpoint($tablet) {
						text-align: left;
					}
				}
			}
			.unreadCount {
				position: relative;
				height: 20px;
				width: 22px;
				
				svg {
					height: 100%;
					width: 100%;
					fill: $blue;
					vertical-align: middle;
				}
				label {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					font-size: 9px;
					color: $white;
					text-align: center;
				}
			}
			table {
				margin-bottom: 20px;
				
				tr:first-of-type td {
					border-top: 3px solid $navyblue;
				}
				.carousel {
					padding-bottom: 30px;
					
					.control-dots {
						margin: 6px 0;
						
						.dot {
							width: 6px;
							height: 6px;
							margin: 0 4px;
							background-color: $darkblue;
							
							&.selected {
								background-color: $blue;
							}
						}
					}
				}
				h3 {
					line-height: 18px;
					white-space: normal;
				}
				strong {
					color: $blue;
				}
				.summary {
					width: 200px;
					
					label {
						text-align: right;
					}
				}
			}
			&.mobileList {
				h4 {
					border-bottom: 3px solid $navyblue;
				}
				a {
					display: block;
					padding: 20px 0;
					border-bottom: 1px solid $lightgrey;
					color: $darkblue;
					
					figure {
						@include maintain-aspect-ratio(16, 9);
						margin-bottom: 10px;
					}
					strong {
						color: $blue;
					}
					.unreadCount {
						float: right;
					}
					.summary {
						margin-top: 10px;
					}
					
					&:last-of-type() {
						border-bottom: none;
					}
				}
			}
		}
		.noItems {
			line-height: 40px;
		}
	}
}

.event {
	header {
		.actions {
			grid-column: 1;
			grid-row: 1;
			align-self: center;
			@include breakpoint($tablet) {
				grid-column: 4;
			}
			
			.btn {
				background-color: $white;
				color: $blue;
				
				&.outline {
					grid-column: 3 / span 1;
					border-color: $white;
					background-color: transparent;
					color: $white;
			
					svg {
						fill: $white;
					}
				}
			}
		}
	}
	
	.eventSummary {
		display: grid;
		margin-bottom: 10px;
		@include breakpoint($tablet) {
			grid-template-columns: 3fr 1fr;
		}
		
		.details {
			display: grid;
			align-content: start;
			grid-gap: 30px;
			padding: 20px;
			background-color: $white;
			@include breakpoint($tablet) {
				min-height: 230px;
				grid-template-columns: repeat(3, 1fr);
			}
			
			.title {
				@include breakpoint($tablet) {
					grid-column: 1 / span 2;
				}
				
				h3 {
					color: $blue;
				}
			}
			.token {
				color: $darkgrey;
				@include breakpoint($tablet) {
					grid-column: 3;
					place-self: start end;
				}
			}
			.contact, .venue, .delivery {
				label, strong {
					display: block;
					font-weight: $bold;
				}
				label {
					color: $blue;
				}
				a {
					color: $darkblue;
				}
			}
		}
		.tasks {
			padding-left: 10px;
			
			& > div {
				padding: 0 10px;
				border-top: 1px solid $white;
				color: $white;
				line-height: 40px;
			}
		}
	}
	.subRental {
		display: grid;
		margin-bottom: 10px;
		@include breakpoint($tablet) {
			grid-template-columns: 3fr 1fr;
		}
		
		.items, .summary {
			padding: 10px 20px 20px;
		}
		.items {
			position: relative;
			grid-row: 2;
			background-color: $white;
			@include breakpoint($tablet) {
				grid-column: 1;
				grid-row: 1;
			}
			
			h3 {
				line-height: 40px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				
				span, label {
					margin-left: 5px;
				}
				span {
					color: $blue;
				}
				label {
					font-size: 12px;
					font-weight: $regular;
					line-height: 40px;
					color: $darkgrey;
				}
			}
			h4 {
				color: $blue;
			}
			& > div {
				margin-bottom: 20px;
			}
			.grid {
				.title {
					font-size: 16px;
					font-weight: $bold;
					@include breakpoint($tablet) {
						font-size: 12px;
					}
				}
				tbody {
					&:first-of-type tr:first-of-type td {
						border-top: 2px solid $blue;
					}
					td:not(.image) {
						height: 50px;
						
						&.image {
							height: auto;
						}
					}
					
					&.itemContent {
						.bottom td:not(.image) {
							vertical-align: bottom;
						}
					}
					&.mobileItemContent {
						figure {
							@include maintain-aspect-ratio(16,9);
							margin-top: 5px;
						}
					}
				}
				.caption {
					color: $darkgrey;
					white-space: inherit;
				}
			}
			.subtotal {
				border-top: 2px solid $darkblue;
				line-height: 30px;
				text-align: right;
				
				strong {
					margin-right: 5px;
				}
			}
			
			&:before {
				content: " ";
				position: absolute;
				top: 10px;
				left: -5px;
				height: 40px;
				width: 5px;
				background-color: $blue;
			}
		}
		.summary {
			grid-row: 1;
			align-self: start;
			display: grid;
			grid-template-columns: 1fr 1fr;
			background-color: $darkblue;
			color: $white;
			@include breakpoint($tablet) {
				grid-column: 2;
				grid-row: 1;
			}
			
			h4 {
				margin: 10px 0 30px;
				line-height: 20px;
				color: $blue;
				
				span {
					display: block;
					color: $white;
				}
			}
			a:not(.btn) {
				place-self: start end;
				position: relative;
				line-height: 40px;
				
				svg {
					height: 21px;
					width: 23px;
					fill: $blue;
					vertical-align: middle;
				}
				label {
					position: absolute;
					top: 12px;
					left: 0;
					width: 23px;
					font-size: 9px;
					line-height: 13px;
					color: $white;
					text-align: center;
				}
			}
			.billingDetails, .actions {
				grid-column: 1 / span 2;
			}
			.actions {
				grid-template-columns: 1fr 1fr;
				grid-row-gap: 5px;
				margin-top: 10px;
			}
		}
	}
}

.paymentModal {
	header h4 {
		@include breakpoint($tablet) {
			position: relative;
			color: $white;
			z-index: 1100;
		}
	}
	section {
		display: grid;
		@include breakpoint($tablet) {
			grid-template-columns: 1fr 1fr;
		}
		
		.summary {
			display: none;
			padding: 40px 20px 20px;
			margin: -40px 0 -30px -20px;
			background-color: $blue;
			color: $white;
			@include breakpoint($tablet) {
				display: block;
			}
			
			svg {
				width: 125px;
				margin: 30px 0 30px 70px;
			}
			h4 {
				color: $white;
			}
			.billingDetails div > span {
				font-weight: $bold;
			}
		}
		.scrollarea {
			margin-right: -10px;
			padding-right: 15px;
		}
		.details {
			padding: 20px 0 0;
			@include breakpoint($tablet) {
				padding: 0 0 0 20px;
			}
			
			& > .fields {
				margin-bottom: 20px;
				
				& > label {
					display: block;
					font-weight: $bold;
					line-height: 22px;
					color: $blue;
				}
				select {
					width: 100%;
					margin-bottom: 5px;
				}
				
				&.amount {
					display: grid;
					grid-template-columns: 2fr 1fr;
					
					label {
						grid-column: 1 / span 2;
					}
					.radioList {
						grid-column: 1;
						grid-row: 2 / span 3;
					}
					strong, input[type="text"] {
						min-width: 0;
						text-align: right;
					}
					strong {
						margin-right: 10px;
					}
				}
				.btn {
					width: 100%;
				}
			}
		}
	}
}

.changeRequestModal {
  background-color: $white;
  display: grid;
  grid-template: auto/repeat(1, 1fr) !important;
  grid-template-columns: 1fr 1fr;
  grid-column: span 4;
  padding: 10px 0;

  @include breakpoint($tablet) {
    grid-template-columns: 1fr 2fr 2fr 1fr;
  }

  padding: 10px 20px 20px;
  margin-bottom: 20px;

  .itemChanges {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0;
    border-bottom: 1px solid #C1CDD7;

    @include breakpoint($tablet) {
      grid-template-columns: 1fr 2fr 2fr 1fr;
    }

    .subChangeRequestStatus {
      svg {
        margin: 0 5px;

        width: 14px !important;
        height: 11px !important;
        fill: #C1CDD7;

        &.approved {
          margin-left: 21%;
          fill: #54C06E !important;
        }

        &.denied {
          margin-left: 21%;
          fill: #D3347F !important;
        }

        &.filledNotes {
          fill: #278ADA !important;
        }
      }
    }

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    span {
      color: black;
      line-height: 16px;
      font-size: 14px;
    }

    label {
      font-size: 14px;
    }

    h4 {
      line-height: 16px;
    }
  }

  .closeBtn {

    a {
      float: right;

      svg {
        height: 10px;
        width: 10px;
      }
    }
  }
}