html {
	height: 100%;
	background: $lightgrey;
}

body {
	height: 100%;
	overflow-x: auto !important;
    overflow-y: scroll !important;
}

body, tr, td, input, textarea, select, button {
	font-family: $fontfamily;
	font-size: 14px;
	line-height: 20px;
	color: $darkblue;
	@include breakpoint($tablet) {
		font-size: 12px;
		line-height: 18px;
	}
}

input[type="text"], input[type="email"], input[type="password"], textarea, select {
	height: 35px;
	padding: 0 10px;
	background-color: $medlightgrey;
	border: none;
	line-height: 35px;
	outline: none;
	@include border-radius(2px);
	@include breakpoint($tablet) {
		height: 30px;
		line-height: 30px;
	}
	
	&:required, &:invalid {
		box-shadow: none;
	}
	&.error {
		padding: 0 9px;
		border: 1px solid $red;
		line-height: 33px;
		color: $red;
		@include breakpoint($tablet) {
			line-height: 28px;
		}

		&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			color: $red;
		}
		&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: $red;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: $red;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: $red;
		}
	}
}

select {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: -moz-none;
	-ms-user-select: none;

	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;

	padding-right: 27px;
	background-image: url(svg-encode(down-arrow(#466B88)));
	background-repeat: no-repeat;
	background-position: right 8px center;
	background-size: 12px 8px;
}

/* Override the color of the placeholder text */
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	color: $darkgrey;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: $darkgrey;
	opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: $darkgrey;
	opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: $darkgrey;
}

input[type="search"], .search {
	height: 35px;
	width: 100%;
	padding: 0 10px 0 30px;
	margin: 5px 0;
	background-color: $white;
	background-image: url(svg-encode(search-icon($blue)));
	background-repeat: no-repeat;
	background-position: left 10px center;
	background-size: 11px 11px;
	line-height: 35px;
	border: none;
	outline: none;
	@include border-radius(2px);
	-webkit-appearance: textfield;
	@include breakpoint($tablet) {
		height: 30px;
		width: 235px;
		line-height: 30px;
	}
	
	/* Override the search clear button */
	&::-webkit-search-cancel-button {
		-webkit-appearance: none;
	}
}

h1 {
	margin: 0;
	font-size: 22px;
	font-weight: $bold;
	line-height: 30px;
	color: $white;
}
h2 {
	font-size: 22px;
	font-weight: $bold;
	line-height: 30px;
	color: $darkblue;
}
h3 {
	font-size: 16px;
	font-weight: $bold;
	line-height: 24px;
	color: $darkblue;
}
h4 {
	font-size: 14px;
	font-weight: $bold;
	line-height: 40px;
	color: $darkblue;
}

section {
	position: relative;
	clear: both;
}
figure {
	position: relative;
	background-color: $medlightgrey;
	overflow: hidden;
	
	svg {
		position: absolute;
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
		height: 25%;
		fill: $white;
	}
	img {
		display: block;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
p {
	margin-bottom: 10px;
}
a {
	color: $blue;
	outline: none;
	text-decoration: none;
	cursor: pointer;
	
	* {
		cursor: pointer;
	}
}
strong, b {
	font-weight: $bold;
}
em {
	font-style: italic;
}
ul.bullets {
	margin-left: 12px;
	list-style: inherit;
}

.warn {
	font-size: 10px;
	color: $red;
}
.caption {
	font-size: 12px;
	color: $darkgrey;
	@include breakpoint($tablet) {
		font-size: 9px;
	}
}
.highlight {
	color: $blue;
}
.strikeThrough {
	color: $darkgrey;
	text-decoration: line-through;
	
	* {
		text-decoration: line-through;
	}
}

svg {
	vertical-align: middle;
	
	&.orange {
		fill: $orange !important;
	}
	&.medlightgrey {
		fill: $medlightgrey !important;
	}
	&.darkgrey {
		fill: $darkgrey !important;
	}
	&.green {
		fill: $green !important;
	}
}