/// Base path for assets (fonts, images...),
/// should not include trailing slash
/// @access public
/// @type String
$asset-base-path: '../..' !default;

/// Asset URL builder
/// @access private
/// @param {String} $type - Asset type, matching folder name
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the asset
@function asset($type, $file) {
	@return url($asset-base-path + '/' + $type + '/' + $file);
}

/// Image asset helper
/// @access public
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the image
/// @require {function} asset
@function image($file) {
	@return asset('images', $file);
}

@mixin responsive-img {
	max-width: 100%;
	height: auto;
	display: block;
}

@mixin text-gradient($direction, $color1, $color2) {
	background: linear-gradient($direction, $color1, $color2);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin bkgd-gradient($direction, $start-color, $end-color, $image: null) {
    background-color: $start-color;
    background: $image, -webkit-gradient(linear, left top, right top, from($start-color), to($end-color));
    background: $image, -webkit-linear-gradient($direction, $start-color, $end-color);
    background: $image,    -moz-linear-gradient($direction, $start-color, $end-color);
    background: $image,     -ms-linear-gradient($direction, $start-color, $end-color);
    background: $image,      -o-linear-gradient($direction, $start-color, $end-color);
    background: $image,         linear-gradient($direction, $start-color, $end-color);
}

@mixin border-gradient($width, $direction, $start-color, $end-color, $imp: null) {
	border-color: $start-color $imp;
    border-width: $width $imp;
    border-style: solid $imp;
    -webkit-border-image: -webkit-gradient(linear, 0 0, 0 100%, from($start-color), to($end-color)) 1 100% $imp;
    -webkit-border-image: -webkit-linear-gradient($direction, $start-color, $end-color) 1 100% $imp;
    -moz-border-image:       -moz-linear-gradient($direction, $start-color, $end-color) 1 100% $imp;
    -o-border-image:               -o-linear-gradient($direction, $start-color, $end-color) 1 100% $imp;
    border-image:                 linear-gradient($direction, $start-color, $end-color) 1 100% $imp;
}

@mixin maintain-aspect-ratio($width, $height) {
	position: relative;

	&:before{
		display: block;
		content: " ";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}

	> * {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@mixin center-column {
	@include breakpoint($tablet) {
		max-width: 1020px;
		margin-left: auto;
		margin-right: auto;
	}
}

@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}
$encoding-reference: (
	//('%','%25'),  // Encode "%" first, otherwise the "%" from encoded code would be encoded again (which would be bad)
	('<','%3C'),
	('>','%3E'),
	//('"','%22'),  // Replace " with ' because that's shorter than %22 and normally working
	('"','\''),
	('#','%23'),
	('&','%26')
);
@function svg-encode($svg) {
	@each $char, $encoded in $encoding-reference {
		$svg: str-replace($svg, $char, $encoded);
	}
	@return 'data:image/svg+xml,' + $svg;
}

// Specific icons to color
@function down-arrow($color) {
	@return '<svg viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg"><path fill="#{$color}" transform="scale(1, -1) translate(0, -8)" d="M6 0L0 5.872 2.174 8 6 4.255 9.826 8 12 5.873z" /></svg>';
}
@function left-arrow($color) {
	@return '<svg viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg"><polygon fill="#{$color}" transform="rotate(270.0) translate(-10.0, -2.0)" points="4.00003849 2 -2 7.87245583 0.173670785 9.99989955 3.99998717 6.25498788 7.82632921 10 10 7.87253117" /></svg>';
}
@function right-arrow($color) {
	@return '<svg viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg"><polygon fill="#{$color}" transform="rotate(90.0) translate(2.0, -10.0)" points="4.00003849 2 -2 7.87245583 0.173670785 9.99989955 3.99998717 6.25498788 7.82632921 10 10 7.87253117" /></svg>';
}
@function search-icon($color) {
	@return '<svg viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg"><path fill="#{$color}" d="M6.725 6.135a1.85 1.85 0 0 1 0-3.7 1.85 1.85 0 0 1 0 3.7m0-6.135a4.285 4.285 0 0 0-3.884 6.072L0 8.92l2.058 2.062 2.825-2.83a4.242 4.242 0 0 0 1.842.418 4.285 4.285 0 0 0 0-8.569" /></svg>';
}
@function calendar-icon($color) {
	@return '<svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path fill="#{$color}" d="M1.831 10.904c0-.227.18-.411.4-.411h1.672c.221 0 .4.184.4.411v1.112c0 .227-.178.41-.4.41H2.232a.406.406 0 0 1-.401-.41v-1.112zm0-2.87c0-.228.18-.412.4-.412h1.672c.221 0 .4.184.4.411v1.092c0 .227-.178.411-.4.411H2.232a.406.406 0 0 1-.401-.41V8.032zm0-2.88c0-.228.18-.412.4-.412h1.672c.221 0 .4.184.4.411v1.092c0 .227-.178.411-.4.411H2.232a.406.406 0 0 1-.401-.411V5.153zm3.709 5.75c0-.227.18-.411.4-.411h1.963c.221 0 .4.184.4.411v1.112c0 .227-.179.41-.4.41H5.94a.406.406 0 0 1-.401-.41v-1.112zm0-2.87c0-.228.18-.412.4-.412h1.963c.221 0 .4.184.4.411v1.092c0 .227-.179.411-.4.411H5.94a.406.406 0 0 1-.401-.41V8.032zm0-2.88c0-.228.18-.412.4-.412h1.963c.221 0 .4.184.4.411v1.092c0 .227-.179.411-.4.411H5.94a.406.406 0 0 1-.401-.411V5.153zm3.854 5.75c0-.227.18-.411.4-.411h1.963c.221 0 .4.184.4.411v1.112c0 .227-.179.41-.4.41H9.795a.406.406 0 0 1-.4-.41v-1.112zm0-2.87c0-.228.18-.412.4-.412h1.963c.221 0 .4.184.4.411v1.092c0 .227-.179.411-.4.411H9.795a.406.406 0 0 1-.4-.41V8.032zm0-2.88c0-.228.18-.412.4-.412h1.963c.221 0 .4.184.4.411v1.092c0 .227-.179.411-.4.411H9.795a.406.406 0 0 1-.4-.411V5.153zM.7 13.974h12.59c.387 0 .7-.321.7-.717V2.85a.709.709 0 0 0-.7-.718h-2.331V.957A.946.946 0 0 0 10.025 0a.946.946 0 0 0-.932.957v1.176H4.896V.957A.946.946 0 0 0 3.963 0a.946.946 0 0 0-.932.957v1.176H.699c-.386 0-.699.321-.699.718v10.407c0 .396.313.717.7.717z" /></svg>';
}