.mainMenu .kazzamLogo {
	height: 100%;
	width: 70px;
	background: transparent url('../../../images/kazzam/logo.png') no-repeat left center;
	background-size: 67px 30px;
}

.kazzamHome {
	header {
		margin: 180px 0 0;
		@include breakpoint($tablet) {
			grid-template-rows: auto !important;
			margin: 300px 0 0;
		}

		.searchFilter {
			padding: 0 10px;
			@include breakpoint($tablet) {
				grid-row: 2 !important;
				padding: 0;
			}

			input[type="search"] {
				width: 100%;
				padding: 0 10px 0 30px;
				background-image: url(svg-encode(search-icon($blue)));
				@include border-radius(2px);
				margin: 0;
			}
			ul {
				left: 0;
			}
			&:after {
				display: none;
			}
		}
		.headerLine {
			padding: 40px 20px;
			margin: -20px 0 10px;
			background-color: $red;
			color: $white;
			opacity: 0.95;
			@include breakpoint($tablet) {
				grid-column: 2 / span 2;
				place-self: end stretch;
				padding: 0;
				margin: 0;
				background-color: transparent;
				opacity: 1;
				z-index: 1;
			}

			& > * {
				@include breakpoint($tablet) {
					display: inline-block;
					margin: 0;
					vertical-align: middle;
				}
			}
			h1 {
				@include breakpoint($tablet) {
					font-size: 24px;
					line-height: 40px;
				}
			}
		}
	}
}
.kazzamCategories {
	font-size: 14px;

	.large {
		padding: 40px 0 70px;

		h3, ul {
			@include center-column;
		}
		h3 {
			margin-bottom: 15px;
			font-size: 22px;
			font-weight: $bold;
			line-height: 24px;
			color: $red;
			text-align: center;
		}
		ul {
			display: grid;
			grid-gap: 1px;
			@include breakpoint($tablet) {
				grid-template-columns: repeat(3, 1fr);
			}

			li {
				background-color: #282626;

				a {
					display: flex;
					align-items: center;
					font-weight: $bold;
					color: $white;

					img {
						height: 200px;
					}
					label {
						position: relative;
						margin-left: 35px;

						&:before {
							content: "Shop";
							position: absolute;
							left: -30px;
							font-size: 11px;
							font-weight: $bold;
							color: $red;
						}
					}
				}
			}
		}
	}
	.small {
		padding: 50px 0;
		background-color: $white;

		h3, ul {
			@include center-column;
		}
		h3 {
			margin-bottom: 15px;
			font-size: 22px;
			font-weight: $bold;
			line-height: 24px;
			color: $blue;
			text-align: center;
		}
		ul {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 1px;
			@include breakpoint($tablet) {
				grid-template-columns: repeat(4, 1fr);
			}

			li {
				background-color: #DCE2E6;

				a {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 20px 0;

					img {
						height: 130px;
						width: 130px;
						margin-bottom: 15px;
					}
					label {
					}
				}
			}
		}
	}

	svg {
		width: 100px;
		height: 100px;
	}
}
.kazzamTestimonials {
	padding: 40px 0;
	background-color: $white;

	figure {
		@include center-column;
	}
	.carousel {
		@include center-column;

		.slide {
			background-color: $white;
		}
	}
}

.container .kazzamHero {
	grid-column: 1;
	grid-row: 1 / span 2;
	margin-top: 40px;
	@include breakpoint($tablet) {
		grid-column: 1 / span 3;
		grid-row: 1;
	}

	img {
		object-position: 100% 34%;
	}
}
.container .kazzamCategories {
	grid-column: 1;
	grid-row: 4;
	margin-top: -20px;
	@include breakpoint($tablet) {
		grid-column: 1 / span 3;
		grid-row: 3;
		margin-top: -60px;
	}
}
.container .kazzamTestimonials {
	grid-column: 1;
	grid-row: 5;
	margin-top: -20px;
	@include breakpoint($tablet) {
		grid-column: 1 / span 3;
		grid-row: 4;
		margin-top: -60px;
	}

	& + footer {
		grid-row: 6;
		@include breakpoint($tablet) {
			grid-row: 5;
		}
	}
}
