.categories {
	display: grid;
	@include breakpoint($tablet) {
		grid-template-columns: 1fr minmax(auto, 1020px) 1fr;
	}
	
	&.shopLanding {
		margin-top: -230px;
		@include breakpoint($tablet) {
			margin-top: -200px;
		}
	}

	.large, .small {
		display: grid;
		grid-gap: 10px;
		margin: 10px 10px 0;
		background-color: $lightgrey;
		@include breakpoint($tablet) {
			grid-gap: 1px;
			margin: 0;
		}
		
		li {
			position: relative;
			overflow: hidden;
			@include breakpoint($tablet) {
				border-bottom: 1px solid $lightgrey;
			}
			
			a {
				display: block;
				height: 100%;
				width: 100%;
				font-size: 16px;
				@include breakpoint($tablet) {
					font-size: 14px;
				}
			}
			svg {
				position: absolute;
				height: 80px;
				width: 80px;
				fill: $blue;
				z-index: 1;
			}
			
			&:before {
				content: " ";
				position: absolute;
				top: 50%;
				@include border-radius(50%);
				z-index: 0;
			}
		}
	}
	.large {
		@include breakpoint($tablet) {
			grid-column: 2;
			grid-row: 1;
			grid-template-columns: repeat(3, 1fr);
		}
		
		li {
			background-color: $navyblue;
			height: 120px;
			@include breakpoint($tablet) {
				height: 200px;
			}
			
			a {
				padding-left: 160px;
				line-height: 120px;
				color: $white;
				@include breakpoint($tablet) {
					line-height: 200px;
				}
				
				&:before {
					content: "shop";
					margin-right: 5px;
					font-size: 14px;
					font-weight: $bold;
					color: $blue;
					text-transform: capitalize;
					vertical-align: middle;
					@include breakpoint($tablet) {
						font-size: 11px;
					}
				}
			}
			svg {
				top: 50%;
				left: 30px;
				transform: translate(0, -50%);
			}
			
			&:before {
				left: -100px;
				transform: translate(0, -50%);
				height: 250px;
				width: 250px;
				background-color: $darkblue;
			}
		}
	}
	.small {
		grid-template-columns: 1fr 1fr;
		@include breakpoint($tablet) {
			grid-column: 2;
			grid-row: 2;
			grid-template-columns: repeat(4, 1fr);
		}
		
		li {
			background-color: #0C4E83;
			height: 165px;
			@include breakpoint($tablet) {
				height: 200px;
			}
			
			a {
				padding-top: 100px;
				color: $lightgrey;
				text-align: center;
				@include breakpoint($tablet) {
					padding-top: 130px;
				}
				
				label {
					position: relative;
					z-index: 2;
				}
			}
			svg {
				top: 30px;
				left: 50%;
				transform: translate(-50%, 0);
				@include breakpoint($tablet) {
					top: 50px;
				}
			}
			
			&:before {
				left: 50%;
				transform: translate(-50%, -50%);
				height: 130px;
				width: 130px;
				background-color: #0A385D;
			}
		}
	}
	
	.discoverTheLook {
		margin-top: 20px;
		text-align: center;
		@include breakpoint($tablet) {
			grid-column: 2;
			grid-row: 3;
			margin-top: 40px;
		}
		
		.looks {
			li {
				@include breakpoint($tablet) {
					height: 280px;
					
					&:nth-of-type(1), &:nth-of-type(3) {
						height: 220px;
					}
					&:nth-child(3n+2) {
						&:last-of-type, &:nth-last-of-type(2) {
							height: 220px;
						}
					}
				}
			}
		}
		& > .btn {
			margin: 20px auto 0;
		}
	}
	
	.fullListing {
		padding: 40px 20px;
		margin-top: 20px;
		background-color: $blue;
		color: $white;
		@include breakpoint($tablet) {
			grid-column: 1 / span 3;
			grid-row: 4;
			padding: 60px 0;
			margin-top: 60px;
		}
		
		& > h1, & > ul {
			max-width: 1020px;
			margin: 0 auto;
		}
		& > h1 {
			font-size: 36px;
			font-weight: $light;
			text-align: center;
			@include breakpoint($tablet) {
				text-align: left;
			}
			
			a {
				display: block;
				font-size: 14px;
				@include breakpoint($tablet) {
					display: inline-block;
					margin-left: 25px;
					padding-left: 25px;
					border-left: 2px solid $white;
					font-size: 12px;
					vertical-align: middle;
				}
			}
		}
		a {
			color: $white;
			text-decoration: underline;
		}
		& > ul {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 20px;
			margin-top: 30px;
			@include breakpoint($tablet) {
				grid-template-columns: repeat(3, 1fr);
				grid-gap: 40px;
				margin-top: 40px;
			}
			
			& > li {
				display: grid;
				grid-template: 50px 60px 1fr / 1fr;
				text-align: center;
				@include breakpoint($tablet) {
					grid-template: 50px 1fr / 85px 1fr;
					text-align: left;
				}
				
				svg {
					place-self: center;
					height: 50px;
					width: 50px;
					fill: $darkblue;
				}
				h2 {
					place-self: center;
					width: 70%;
					line-height: 24px;
					color: $darkblue;
					@include breakpoint($tablet) {
						place-self: center start;
						width: auto;
					}
				}
				ul {
					@include breakpoint($tablet) {
						grid-column: 2;
					}
					
					li {
						display: block;
						line-height: 25px;
					}
				}
			}
		}
	}
}