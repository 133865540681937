h1 nav {
	display: block;
	margin: 10px 0 -10px;
	font-size: 12px;
	line-height: 18px;

	a {
		display: inline-block;
		margin-right: 20px;
		color: $white;
		opacity: 0.5;

		&.active {
			border-bottom: 2px solid $white;
			opacity: 1;
		}
		&:last-of-type() {
			margin-right: 0;
		}
	}
}

.check {
	position: relative;
	display: inline-block;
	white-space: nowrap;

	input[type="checkbox"] {
		position: absolute;
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		opacity: 0;
		cursor: pointer;
	}
	svg {
		vertical-align: sub;
	}
	label {
		margin-left: 5px;
		white-space: normal;
	}

	&.on > svg {
		color: $blue;
		fill: $white;
	}
	&.off > svg {
		fill: $darkgrey;
	}
	&.disabled {
		cursor: not-allowed;

		label {
			color: $darkgrey;
		}
	}
}

.radio {
	height: auto !important;
	width: auto !important;
	margin-right: 5px !important;

	svg {
		height: 16px;
		width: 17px;
		fill: $darkgrey;
		vertical-align: middle;
	}

	&.checked svg {
		fill: $blue;
	}
}
.radioList {
	margin-bottom: 10px;

	& > label {
		margin: 0 0 5px;

		& > span {
			font-family: inherit !important;
			font-size: inherit !important;
			line-height: inherit !important;
			color: inherit !important;
		}
		&:last-of-type() {
			margin: 0;
		}
	}
}

.autocomplete {
	position: relative;
	margin-bottom: 5px;

	.results {
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		max-height: 400px;
		border: 1px solid $lightgrey;
		overflow: auto;
		z-index: 1;

		li {
			height: 35px;
			padding: 8px 8px 7px;
			background-color: $white;
			color: $blue;;
			@include breakpoint($tablet) {
				height: 30px;
			}

			&.highlight {
				background-color: $lightgrey;
			}
		}
	}
}

.colorSelector {
	position: absolute;
	top: 36px;
	left: 16px;
	border: 4px solid $white;
	@include border-radius(2px);

	.swatch {
		height: 20px;
		width: 100px;
		background-color: white;
		@include border-radius(2px);
		cursor: pointer;

		label {
			position: absolute;
			top: 0;
			left: 10px;
			font-size: 10px;
			line-height: 20px;
			cursor: inherit;
		}
		& > div {
			height: 100%;
			width: 100%;
		}
	}
}

.upload {
	position: relative;
	display: inline-block;
	height: 30px;
	margin-bottom: 5px;
	background-color: $lightgrey;
	line-height: 30px;
	z-index: 0;

	.dropZone {
		width: 100%;
		height: 100%;
		padding: 0 10px 0 40px;
	}
	label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:after {
		content: " ";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 30px;
		background-color: $blue;
		border-radius: 2px;
		z-index: -1;
	}

	svg {
		position: absolute;
		top: 0;
		left: 0;
		height: 30px !important;
		width: 30px !important;
		padding: 8px;
		fill: $white;
	}
}
.dropZone {
	cursor: pointer;

	* {
		cursor: pointer;
	}
}

.cropper {
	overflow: hidden;
	height: 200px;
	width: 100%;
	@include breakpoint($tablet) {
		height: 315px;
		width: 560px;
	}

	.cropper-container {
		.cropper-modal {
			background-color: $darkblue;
		}
		.cropper-crop-box {
			.cropper-point {
				height: 5px;
				width: 5px;
				opacity: 0.75;
			}
		}
	}
}

.dateRange {
	display: inline-grid;
	grid-template-columns: 1fr !important;
	@include breakpoint($tablet) {
		grid-template-columns: repeat(2, 1fr) !important;
		grid-gap: 10px 30px !important;
	}

	p {
		position: relative;
		color: $blue;
		text-align: center;
		@include breakpoint($tablet) {
			grid-column: span 2;

			&:before, &:after {
				content: " ";
				position: absolute;
				top: 50%;
				height: 1px;
				width: 25%;
				background-color: $blue;
			}
			&:before {
				left: 0;
			}
			&:after {
				right: 0;
			}
		}
	}
	& > div {
		position: relative;

		label {
			display: block;
			margin-bottom: 5px;
			font-weight: $bold;
			line-height: 25px;
			color: $blue;
		}
		.react-datepicker-component {
			width: 150px;
			margin-right: 5px;
			@include breakpoint($tablet) {
				width: 125px;
			}
		}
		.rc-time-picker {
			width: 90px;
		}

		@include breakpoint($tablet) {
			&:first-of-type():after {
				content: " ";
				position: absolute;
				bottom: 15px;
				right: -20px;
				height: 1px;
				width: 10px;
				background-color: $blue;
			}
		}
	}
}

.searchFilter {
	position: relative;
	display: flex;
	text-align: center;
	z-index: 20;

	select {
		height: 40px;
		width: 135px;
		padding-left: 30px;
		background-color: $darkblue;
		background-image: url(svg-encode(down-arrow($white)));
		@include border-radius(2px 0 0 2px);
		font-size: 14px;
		line-height: 40px;
		color: $white;
		@include breakpoint($tablet) {
			background-color: $blue;
		}
	}
	input[type="search"] {
		width: calc(100% - 135px);
		height: 40px;
		padding-left: 10px;
		background-image: none;
		@include border-radius(0 2px 2px 0);
		margin: 0 auto;
		font-size: 14px;
		line-height: 40px;
		@include breakpoint($tablet) {
			font-size: 16px;
		}
	}

	ul {
		position: absolute;
		top: 100%;
		left: 135px;
		right: 0;
		@include border-radius(0 0 2px 2px);
		border: 1px solid $lightgrey;
		text-align: left;
		overflow: hidden;

		li {
			padding-left: 20px;
			background-color: $white;
			line-height: 40px;

			a {
				display: block;
				color: $darkblue;
			}

			&:hover {
				background-color: $lightgrey;
			}
		}
	}

	&.open input[type="search"] {
		@include border-radius(2px 2px 0 0);
	}

	&:after {
		content: " ";
		position: absolute;
		top: 0;
		left: 10px;
		height: 40px;
		width: 30px;
		background-image: url(svg-encode(search-icon($white)));
		background-repeat: no-repeat;
		background-position: left 10px center;
		background-size: 11px 11px;
		@include breakpoint($tablet) {
			left: 0;
		}
	}
}

.itemList {
	padding: 0;

	li {
		position: relative;
		border-bottom: 1px solid $lightgrey;
		line-height: 39px;

		a {
			display: block;

			* {
				cursor: pointer;
			}
		}
	}
}

.checkList {
	padding: 0 !important;

	li {
		padding: 0 5px 0 20px !important;
		border-top: 1px solid #267FCB;
		line-height: 39px;
		color: $white;

		.check {
			svg {
				fill: #1D6AA9;
			}
			&.on svg {
				fill: $white;
				color: $blue;
			}
		}
		div:not(.check) {
			padding: 0 15px !important;
		}
		a {
			display: inline-block;
			width: 40px;
			text-align: center;

			svg {
				fill: $darkblue;
			}
		}
	}

	.open, ul li {
		background-color: $darkblue;

		.check {

			svg {
				fill: #1E4360;
			}
			&.on svg {
				fill: $blue;
				color: $white;
			}
		}
		a svg {
			fill: $blue;
		}
	}
	.open {
		border-top: 1px solid #267FCB;
	}
	ul li {
		border-top: 1px solid $darkblue;
	}

	ul li {
		padding-left: 40px !important;
	}
}

.viewToggle {
	position: relative;
	line-height: 30px;
	margin-bottom: 10px;
	@include breakpoint($tablet) {
		height: 30px;
	}

	& > div {
		display: inline-block !important;
		width: auto !important;
		margin-right: 8px;
		vertical-align: middle;
	}
	label {
		white-space: normal;
		@include breakpoint($tablet) {
			white-space: nowrap;
		}
	}
}

.colorPicker {
	display: flex;
	flex-direction: column;

	& > a {
		align-self: end;
		margin-bottom: 10px;
		font-size: 10px;
		color: $white;
		text-decoration: underline;
	}
	ul {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		grid-gap: 4px;
		@include breakpoint($tablet) {
			grid-gap: 5px;
		}

		li {
			@include maintain-aspect-ratio(1,1);
			padding: 5px 4px;
			@include breakpoint($tablet) {
				padding: 3px 2px;
			}


			.check {
				height: 100%;
				width: 100%;
				margin: 0 !important;

				svg {
					height: 100% !important;
					width: 100% !important;
				}

				&.on > svg {
					color: #000;
					fill: transparent;
				}
				&.off > svg {
					fill: transparent;
				}
			}

			&.red {
				background-color: $red;
			}
			&.orange {
				background-color: $orange;
			}
			&.yellow {
				background-color: #FFDF6D;
			}
			&.green {
				background-color: $green;
			}
			&.blue {
				background-color: $blue;
			}
			&.purple {
				background-color: $purple;
			}
			&.pink {
				background-color: $pink;
			}
			&.white {
				background-color: $white;
			}
			&.brown {
				background-color: #6D584C;
				.check.on > svg {
					color: $lightgrey;
				}
			}
			&.black {
				background-color: #000;
				.check.on > svg {
					color: $lightgrey;
				}
			}
			&.grey {
				background-color: #ADADAD;
			}
			&.silver {
				background: linear-gradient(225deg, #E0E7EF 0%, #8897A2 50%, #A7C5D3 100%);
			}
			&.gold {
				background: linear-gradient(224.91deg, #F0DA86 0%, #AF9037 50%, #EBA422 100%);
			}
			&.clear {
				padding: 4px 3px;
				border: 1px solid $darkgrey;
				background: linear-gradient(to top right,
					rgba(0,0,0,0) 0%,
					rgba(0,0,0,0) calc(50% - 0.8px),
					rgba(143,169,190,1) 50%,
					rgba(0,0,0,0) calc(50% + 0.8px),
					rgba(0,0,0,0) 100%);
				@include breakpoint($tablet) {
					padding: 2px 1px;
				}
			}
		}
	}
}

.rTable { display: table; }
.rTableHeading { display: table-header-group; }
.rTableBody { display: table-row-group; }
.rTableFoot { display: table-footer-group; }
.rTableRow { display: table-row; }
.rTableCell, .rTableHead {
	display: table-cell;
	vertical-align: middle;
}

.grid {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;

	.rTableHeading, thead {
		.rTableRow, tr {
			.rTableHead, th {
				height: 40px;
				padding: 0;
				font-size: 12px;
				line-height: 15px;
				color: $blue;;
				@include breakpoint($tablet) {
					font-size: 9px;
					line-height: 12px;
				}
			}
		}
	}
	.rTableBody, tbody {
		.rTableRow, tr {
			.rTableCell, td {
				position: relative;
				height: 40px;
				padding-right: 5px;
				border-top: 1px solid $lightgrey;
				color: $darkblue;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&:last-of-type() {
					padding-right: 0;
				}

				& > input[type="text"], & > textarea, & > select {
					width: 100%;
				}

        .textSpace{
          white-space: normal;
        }
			}
			&.top {
				.rTableCell, td {
					vertical-align: top;
					padding: 5px 0 0;
				}
			}
			&.bottom {
				.rTableCell, td {
					vertical-align: bottom;
					padding: 0 0 5px;
				}
			}
			&.noBorder {
				.rTableCell, td {
					border-top: none;
				}
			}
		}
	}

	&.thumbnail {
		.rTableBody, tbody {
			.rTableRow, tr {
				.rTableCell, td {
					height: auto;

					&.image {
						height: 120px;
						width: 145px;
						padding: 5px 15px 5px 0;
						text-align: center;
						vertical-align: top;
						@include breakpoint($tablet) {
							height: 125px;
							width: 215px;
							vertical-align: middle;
						}

						figure {
							height: 100%;
							@include breakpoint($tablet) {
								@include maintain-aspect-ratio(16, 9);
							}
						}

						&.indent {
							width: 175px;
							@include breakpoint($tablet) {
								width: 245px;
							}
						}
					}
				}
			}
		}
	}

	.sortable {
		position: relative;
		display: inline-block;
		padding-right: 12px;
		margin-right: -12px;
		cursor: pointer;

		&.asc:before, &.desc:before {
			content: " ";
			position: absolute;
			top: 7px;
			right: 0;
			display: inline-block;
			height: 4px;
			width: 8px;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			@include breakpoint($tablet) {
				top: 5px;
			}
		}

		&.asc:before {
			border-top: 4px solid $blue;
			border-bottom: none;
		}
		&.desc:before {
			border-top: none;
			border-bottom: 4px solid $blue;
		}
	}
	.check {
		&.on > svg {
			margin: 0 !important;
			fill: $blue;
		}
		&.off > svg {
			margin: 0 !important;
			fill: $meddarkgrey;
		}
	}
}

.gridWrapper {
	width: 100%;
	padding-bottom: 10px;
	overflow-x: auto;
	@include breakpoint($tablet) {
		padding-bottom: 0;
		overflow-x: inherit;
	}

	&.scroll {
		overflow-x: auto;
	}

	.grid, .detailGrid {
		width: 800px;
		@include breakpoint($tablet) {
			width: 100%;
		}
	}
}


.grid {
	.rTableHeading, thead, .rTableBody, tbody {
		.rTableRow, tr {
			.rTableHead, th, .rTableCell, td {
				&.indent {
					padding-left: 20px !important;
					@include breakpoint($tablet) {
						padding-left: 30px !important;
					}
				}
				&.action {
					width: 36px;
					padding-left: 0;
					padding-right: 0;
					text-align: center;
					overflow: visible;
				}
				&.actionSm {
					width: 20px;
					padding-left: 0;
					padding-right: 0;
					text-align: center;
					overflow: visible;
				}
				&.exSm {
					width: 50px;
				}
				&.sm {
					width: 80px;
				}
				&.medSm {
					width: 120px;
					@include breakpoint($tablet) {
						width: 100px;
					}
				}
				&.med {
					width: 140px;
					@include breakpoint($tablet) {
						width: 120px;
					}
				}
				&.medLg {
					width: 120px;
					@include breakpoint($tablet) {
						width: 200px;
					}
				}
				&.lg {
					width: 120px;
					@include breakpoint($tablet) {
						width: 275px;
					}
				}
				&.date {
					width: 170px;
					@include breakpoint($tablet) {
						width: 135px;
					}
				}
				&.right {
					text-align: right;
					padding-right: 10px;

					&:last-of-type() {
						padding-right: 0;
					}
				}
				&.center {
					text-align: center;
				}
				&.bold {
					font-weight: $bold;
				}
				&.alert {
					svg {
						height: 12px;
						width: 14px;
						margin-right: 5px;
						fill: $orange;
					}
				}
			}
		}
	}
}

.pagerWrapper {
	margin-top: 15px;
	font-size: 12px;
	line-height: 25px;

	.pager {
		width: 150px;
		text-align: center;

		li {
			display: inline-block;
			text-transform: uppercase;

			a {
				display: inline-block;
				height: 100%;
				width: 100%;
				padding: 0 5px;

				svg {
					fill: $blue;
					vertical-align: middle;
				}
			}
			&.disabled {
				opacity: 0.3;

				a {
					cursor: auto;
				}
			}

			&.pages {
				margin: 0 5px;
				font-size: 9px;
				color: $darkblue;

				input[type="text"] {
					height: 25px;
					width: 25px;
					padding: 0;
					margin-right: 5px;
					background-color: $blue;
					line-height: 25px;
					color: $white;
					text-align: center;
				}
				span {
					display: inline-block;
					margin-left: 5px;
					font-size: 12px;
					color: $blue;;
				}
			}
		}
	}
}

.tileToggle {
	a {
		svg {
			height: 12px;
			width: 19px;
			margin-right: 10px;
			fill: $meddarkgrey;
		}

		&:last-of-type() svg {
			margin-right: 0;
		}
		&.active svg {
			fill: $blue;
		}
	}
}

.signaturePad {
	height: 70px;
	margin-bottom: 10px;

	a {
		position: relative;
		display: inline-block;
		height: 100%;
		width: 100%;
		background-color: $white;
		@include border-radius(2px);

		img {
			height: 100%;
			width: 100%;
		}

		&:after {
			content: "X";
			position: absolute;
			bottom: 5px;
			left: 7px;
			right: 7px;
			border-bottom: 1px solid $darkblue;
			font-size: 12px;
			font-weight: $light;
			color: $darkblue;
		}
	}
}
.signature {
	position: relative;
	margin-bottom: 10px;

	&:after {
		content: "X";
		position: absolute;
		bottom: 5px;
		left: 7px;
		right: 7px;
		border-bottom: 1px solid $darkblue;
		font-size: 12px;
		font-weight: $light;
		color: $darkblue;
	}
}

.companyDetails {
	display: grid;
	grid-template-columns: 1fr 3fr;
	align-content: start;

	label {
		font-size: 11px;
		font-weight: $bold;
		text-transform: uppercase;
	}
	& > div {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.favoritesHeader{
	display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.info {
	display: inline-block;
	height: 15px;
	width: 15px;
	color: $lightgrey;
	cursor: pointer;

	svg {
		height: 100%;
		width: 100%;
		fill: $darkgrey;
		vertical-align: baseline;
	}

}
.tooltip {
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	padding: 0 10px 20px;
	@include border-radius(3px);
	outline: none;
	background-color: $white;
	opacity: 1;
	cursor: default;
	@include breakpoint($tablet) {
		width: 425px;
		padding: 0 20px 20px;
	}

	header {
		a {
			position: absolute;
			top: 10px;
			right: 10px;
			height: 10px;
			width: 10px;
			line-height: 0;
			@include breakpoint($tablet) {
				right: 10px;
			}

			svg {
				height: 100%;
				width: 100%;
				fill: $blue;
			}
		}
	}
	main {
		.scrollarea {
			max-height: 300px;
			@include breakpoint($tablet) {
				max-height: 400px;
			}
		}
	}
	footer {
		margin-top: 10px;
	}

	&.large {
		width: 100%;
		@include breakpoint($tablet) {
			width: 600px;
		}
	}

  &.very-large {
		width: 100%;

    @include breakpoint($tablet) {
      width: 1024px;
    }
  }

	&.signatureModal {
		.actions {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.dialog {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 75%;
	padding: 0;
	background-color: transparent;
	text-align: center;
	opacity: 1;
	@include breakpoint($tablet) {
		width: 400px;
	}

	header {
		display: none;
	}
	main {
		padding: 0;
		color: $white;
		font-size: 22px;
		line-height: 25px;
	}
	footer {
		display: block;
		margin-top: 20px;

		button, input[type="submit"], .btn {
			width: 25%;
			margin-bottom: 5px;

			&.cancel {
				background-color: $darkgrey;
				border-color: $darkgrey;
				color: $white;
			}
			&.warn {
				width: 100%;
				margin-bottom: 40px;
			}
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1050;
	background-color: rgba(7, 24, 39, 0.85);
	overflow: hidden;
	cursor: pointer;
}

.spinner {
	clear: both;
	padding: 40px 0;
	width: 100% !important;
	text-align: center;

	svg {
		height: 40px;
		width: 40px;
		fill: none;
		stroke: $blue;
		stroke-width: 2px;

		circle {
			stroke-opacity: 0.15;
		}
	}
	p {
		margin-top: 20px;
	}
}
.modalSpinner {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	color: $meddarkgrey;
	z-index: 1400;
	@include breakpoint($tablet) {
		white-space: nowrap;
	}

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.react-datepicker-row {
  div {
    padding: 5px !important;
  }
}
