@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700');

//Font Variables
$fontfamily: "Heebo", Helvetica, sans-serif;

// Font Weight
$thin: 100;
$light: 300;
$regular: 400;
$semibold: 500;
$bold: 700;

// Colors
$blue: #278ADA;
$medblue: #1B4160;
$darkblue: #0E2C44;
$navyblue: #071827;

$darkgrey: #8FA9BE;
$meddarkgrey: #C1CDD7;
$medlightgrey: #E0E5E9;
$lightgrey: #E5E8E9;
$white: #FFFFFF;

$red: #CA3A3A;
$pink: #D3347F;
$orange: #FF886D;
$purple: #966CB8;
$green: #54C06E;

// Min Screen Widths
$phone: 0px;
$tablet: 769px;
$desktop: 1200px;