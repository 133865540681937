.shop {
	header {
		position: relative;

		.numberSelect {
			position: absolute;
			bottom: -40px;
			right: 0;
			background-color: $darkblue;
			background-image: url(svg-encode(down-arrow($blue)));
			color: $white;
			z-index: 1;
			@include breakpoint($tablet) {
				position: static;
				grid-column: 4;
				place-self: end end;
				margin-bottom: 10px;
			}
		}
	}

	.business {
		padding: 20px;
		margin-bottom: 20px;
		background-color: $white;
		@include breakpoint($tablet) {
			display: flex;
		}

		figure {
			@include maintain-aspect-ratio(16, 9);
			margin: 0 0 20px;
			@include breakpoint($tablet) {
				width: calc(#{span(1 of 3)} - 30px);
				margin: 0 30px 0 0;
			}
		}
		.summary {
			@include breakpoint($tablet) {
				width: span(1 of 3);
				padding: 10px 75px 0 0;
			}
		}
		.details {
			@include breakpoint($tablet) {
				width: span(1 of 3);
				padding: 10px 0 0 20px;
				border-left: 1px solid $medlightgrey;
			}
		}
	}
	.businessNoItems {
		padding: 40px 0;
		background-color: $white;
		text-align: center;
		@include breakpoint($tablet) {
			padding: 75px 0;
		}
		
		.icon {
			margin: 0 auto 25px;
		}
		h2 {
			margin: 0 auto 20px;
			@include breakpoint($tablet) {
				width: 350px;
			}
		}
		form {
			display: inline-grid;
			grid-template-columns: 3fr 1fr;
			grid-column-gap: 5px;
		}
	}

	.storefront {
		display: grid;
		grid-gap: 10px 0;
		align-items: flex-start;
		@include breakpoint($tablet) {
			grid-template-columns: 1fr 2fr;
			grid-gap: 0 10px;
		}

		.filter {
			align-self: start;
			padding: 20px;
			background-color: $blue;
			color: $white;

			.dateSelector {
				display: grid;
				grid-gap: 10px 5px;
				grid-template-columns: 2fr 3fr 2fr;
				align-items: center;
				margin-bottom: 20px;
				@include breakpoint($tablet) {
					grid-template-columns: 1fr 125px 85px;
				}

				input[type="text"], .react-datepicker-input {
					background-color: $white;
				}
				.react-datepicker-component {
					.react-datepicker-input:before {
						background-color: $darkblue;
					}
				}
			}
			.quantityFilter {
				margin: 0 -20px;
				padding: 20px;
				background-color: $darkblue;
				
				.rc-slider {
					width: calc(100% - 80px);
					margin: 40px 40px 0;
					
					&:before, &:after {
						position: absolute;
						top: -2px;
					}
					&:before {
						content: "0";
						left: -22px;
					}
					&:after {
						content: "500+";
						right: -40px;
					}
				}
			}
			.colorFilter {
				display: flex;
				margin: 0 -20px;
				padding: 20px;
				border-top: 1px solid $blue;
				background-color: $darkblue;
				
				.colorPicker {
					width: 100%;
					margin-left: 20px;
					
					ul {
						padding-right: 40px;
					}
				}
			}
			& > .checkList {
				margin: 0 -20px;
			}
		}
		.mobileFilter {
			& > a {
				display: inline-block;
				line-height: 45px;

				svg {
					height: 25px;
					width: 25px;
					fill: $white;
					vertical-align: middle;
				}
			}
			.chipWrapper {
				width: calc(100vw - 20px);
				line-height: 40px;
				white-space: nowrap;
				overflow-x: auto;

				.chip {
					margin-right: 20px;
					background-color: transparent;
					color: $white;

					span {
						padding: 0;
					}
					svg {
						height: 15px;
						width: 16px;
						margin: 0 0 0 5px;
						fill: $red;
					}
				}
			}
		}
		.spinner {
			background-color: $white;
		}
	}
	
	.icon {
		height: 130px;
		width: 130px;
		background-color: $blue;
		@include border-radius(50%);
		text-align: center;
		
		svg {
			height: 100%;
			width: 100%;
			padding: 25px;
			fill: $white;
		}
	}
}

.storeItems {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
	@include breakpoint($tablet) {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px 10px;
	}

	.item, .lowItem, .noItems {
		position: relative;
		background-color: $white;
		color: $darkblue;
	}
	.item {
		min-width: 0;
		height: 300px;
		padding: 10px;

		a:not(.caption):not(.btn) {
			display: block;
			height: 160px;
			padding-bottom: 10px;
			margin-bottom: 10px;
			border-bottom: 1px solid $medlightgrey;

			figure {
				height: 100%;
			}
		}
		label {
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		strong {
			display: block;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		& > div {
			position: absolute;
			bottom: 10px;
			left: 10px;
			right: 10px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 5px;

			& > * {
				min-width: 0;
				margin: 0;
			}
			input[type="text"] {
				text-align: center;
			}
			.info {
				position: absolute;
				bottom: 8px;
				left: calc(50% - 20px);
			}
			a.btn {
				background-color: $green;
			}
		}
	}
	.lowItem, .noItems {
		.icon {
			margin: 0 auto 25px;
		}
	}
	.lowItem {
		min-width: 0;
		height: 300px;
		padding: 20px;
		text-align: center;
		@include breakpoint($tablet) {
			padding: 50px 35px 30px;
		}
	}
	.noItems {
		grid-column: span 2;
		padding: 20px;
		text-align: center;
		@include breakpoint($tablet) {
			grid-column: span 3;
			height: 590px;
			padding: 70px 170px 220px;
		}
		
		h2, p {
			margin-bottom: 20px;
		}
		form {
			display: grid;
			grid-template-columns: 1fr 85px;
			grid-column-gap: 5px;
		}
	}
	
	& > p {
		grid-column: span 2;
		padding: 0 20px 20px;
		background-color: $white;
		line-height: 40px;
		@include breakpoint($tablet) {
			grid-column: span 3;
		}
	}
	.pagerWrapper {
		grid-column: 1 / span 2;
		@include breakpoint($tablet) {
			grid-column: 1 / span 3;
		}
	}
}

.filterModal {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transform: translate(0, 0);
	padding: 20px 20px 60px;
	background-color: $blue;
	@include border-radius(0);
	color: $white;
	z-index: 1000;
	
	header {
		display: none;
	}
	main {
		.linkClose {
			display: block;
			height: 15px;
			width: 15px;
			
			svg {
				fill: $white;
			}
		}
		h3 {
			display: block;
			margin: 20px 0;
		}
		.dateSelector {
			display: grid;
			grid-gap: 10px 5px;
			grid-template-columns: 2fr 3fr 2fr;
			align-items: center;
			@include breakpoint($tablet) {
				grid-template-columns: 1fr 125px 85px;
			}

			input[type="text"], .react-datepicker-input {
				background-color: $white;
			}
			.react-datepicker-component {
				.react-datepicker-input:before {
					background-color: $darkblue;
				}
			}
		}
		.quantityFilter {
			margin: 20px -20px 0;
			padding: 20px;
			background-color: $darkblue;
			
			.rc-slider {
				width: calc(100% - 80px);
				margin: 40px 40px 0;
				
				&:before, &:after {
					position: absolute;
					top: -2px;
					font-size: 12px;
					line-height: 18px;
				}
				&:before {
					content: "0";
					left: -22px;
				}
				&:after {
					content: "500+";
					right: -40px;
				}
			}
		}
		.colorFilter {
			display: flex;
			margin: 0 -20px;
			padding: 20px;
			border-top: 1px solid $blue;
			background-color: $darkblue;
			
			.colorPicker {
				width: 100%;
				margin-left: 20px;
			}
		}
		.scrollarea {
			margin: 0 -20px;
			max-height: inherit;
			height: calc(100vh - 420px);
		}
	}
}