.mainMenu {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	max-width: 1020px;
	margin: 0 auto;

	& > div {
		display: flex;
		height: 100%;
	}
	.headerLogo {
		height: 100%;
		width: 125px;
		background: transparent image('logos/logo_blue.png') no-repeat left center;
		background-size: 125px 25px;
	}
	& > label {
		display: inline-block;
		padding-left: 15px;
		margin-left: 15px;
		border-left: 1px solid $darkgrey;
		font-size: 10px;
		line-height: 20px;
		color: $darkgrey;
	}
	.links {
		margin-left: auto;
		align-items: center;
		a {
			display: inline-block;
			padding: 0 10px;
			margin-right: 10px;
			border-bottom: 5px solid transparent;
			line-height: 40px;
			color: $darkblue;

			&.active, &:hover {
				border-color: $blue;
				color: $blue;
			}
		}
		.profileMenu {
			position: relative;
			display: flex;
			align-items: center;
			padding: 0 10px;

			figure {
				display: block;
				height: 22px;
				width: 22px;
				background-color: $navyblue;
				@include border-radius(50%);

				& > * {
					display: block;
					height: 100%;
					width: 100%;
				}
				label {
					font-size: 11px;
					font-weight: bold;
					line-height: 22px;
					color: $white;
					text-align: center;
				}
				img {
				}
			}
			svg {
				margin-left: 5px;
				fill: $navyblue;
				vertical-align: middle;
			}

			ul {
				position: absolute;
				top: 100%;
				right: 0;
				background-color: $white;
				box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.25);
				font-weight: $regular;
				white-space: nowrap;

				li {
					a {
						display: block;
						padding: 0 25px;
						border: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}

			&:hover {
				figure {
					background-color: $blue;
				}
			}
		}
	}
	.callouts {
		align-items: center;
		position: relative;

		.location {
			position: relative;
			width: 75px;
			padding-left: 20px;
			line-height: 40px;
			text-align: right;
			white-space: nowrap;

			svg {
				margin-left: 5px;
				fill: $navyblue;
				vertical-align: middle;
			}
			div {
				position: absolute;
				top: 100%;
				left: -30px;
				width: 280px;
				height: 70px;
				padding: 0 30px 20px;
				font-weight: $regular;
				white-space: nowrap;

				form {
					width: 220px;
					padding: 0 5px;
					background-color: $white;

					input[type="text"] {
						width: 50%;
						margin: 0 5px 0 0;
						background-color: $navyblue;
						color: $meddarkgrey;

						/* Override the color of the placeholder text */
						::-webkit-input-placeholder { /* WebKit, Blink, Edge */
							color: $meddarkgrey;
						}
						:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
							color: $meddarkgrey;
						}
						::-moz-placeholder { /* Mozilla Firefox 19+ */
							color: $meddarkgrey;
						}
						:-ms-input-placeholder { /* Internet Explorer 10-11 */
							color: $meddarkgrey;
						}
					}
					.btn {
						width: calc(50% - 5px);
						margin: 0;
					}
				}
			}
		}
		.icon {
			position: relative;
			padding-left: 20px;
			color: $white;
			text-align: center;

			svg {
				height: 23px;
				fill: $navyblue;
				vertical-align: middle;
			}
			label {
				position: absolute;
				top: 4px;
				width: 20px;
				font-size: 9px;
				line-height: 13px;
				color: $white;
			}

			&.active, &:hover {
			}

			&.message label {
				left: 22px;
			}
			&.cart label {
				left: 27px;
			}
			&.favorites label {
				left: 24px;
			}

			&:hover svg {
				fill: $blue;
			}
		}

		&:before {
			content: " ";
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			height: 50%;
			width: 1px;
			background-color: $darkblue;
		}
	}
	.buttons {
		// align-items: start;
    // position: relative;
    margin-top: 20px;
		margin-left: 20px;

		.btn {
			margin-left: 20px;
			height: 25px;
			line-height: 25px;

			&.outline {
				line-height: 23px;
			}
		}

		&:before {
			content: " ";
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			height: 50%;
			width: 1px;
			background-color: $darkblue;
		}
	}
}

.mobileMenu {
	position: relative;
	grid-column: 1;
	grid-row: 1;
	padding: 10px;

	.headerLogo {
		position: absolute;
		top: 8px;
		left: calc(50%);
		transform: translate(-50%, 0);
		height: 24px;
		width: 46px;
		background: transparent image('logos/mobile_blue.png') no-repeat center center;
		background-size: 46px 24px;
	}
	.topLink {
		svg {
			height: 15px;
			width: 17px;
			fill: $blue;
			vertical-align: middle;
		}

		&.cart {
			position: relative;
			float: right;

			svg {
				height: 23px;
				width: 30px;
			}
			label {
				position: absolute;
				top: 4px;
				left: 8px;
				width: 20px;
				font-size: 9px;
				line-height: 13px;
				color: $white;
				text-align: center;
			}
		}
	}

	.mobileMenuOverlay {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 10px;
		background: $white image('logos/mobile_blue.png') no-repeat center top 8px;
		background-size: 46px 24px;
		color: $darkblue;
		z-index: 100;

		a {
			color: $blue;
		}
		.topLink {
			svg {
				fill: $blue;
			}
			&.cart label {
				color: $white;
			}
		}
		nav {
			padding: 60px 30px 0;

			& > * {
				position: relative;
				display: block;
				border-top: 1px solid $lightgrey;
				font-size: 24px;
				font-weight: $bold;
				line-height: 50px;
				color: $darkblue;

				&.message, &.cart {
					position: relative;

					svg {
						position: absolute;
						top: 50%;
						right: 0;
						transform: translate(0, -50%);
						fill: $blue;
					}
					label {
						position: absolute;
						width: 28px;
						font-size: 9px;
						line-height: 13px;
						color: $white;
						text-align: center;
					}
				}
				&.message {
					svg {
						height: 28px;
						width: 30px;
					}
					label {
						top: 16px;
						right: 0;
					}
					&:before {
						content: "Messages";
					}
				}
				&.cart {
					svg {
						height: 31px;
						width: 40px;
					}
					label {
						top: 17px;
						right: 2px;
					}
				}
			}

			&.noGreeting {
				margin-top: 80px;
			}
		}
		.location {
			margin-top: 30px;
			font-size: 14px;
			line-height: 21px;
			color: $blue;
			text-align: center;

			input[type="text"], .btn {
				height: 40px;
				font-size: 14px;
				line-height: 40px;
			}
			input[type="text"] {
				width: 100px;
				margin-right: 5px;
			}
			.btn {
				width: 85px;
			}
		}
		.signOut {
			position: absolute;
			bottom: 60px;
			left: 50%;
			transform: translate(-50%, 0);
			padding-top: 30px;
			font-size: 10px;
			text-transform: uppercase;
			text-align: center;

			svg {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, 0);
				height: 25px;
				width: 25px;
				color: $white;
				fill: $blue;
			}
		}
		.footerLinks {
			position: absolute;
			bottom: 20px;
			left: 0;
			right: 0;
			display: grid;
			grid-template-columns: 1fr 1fr;
			justify-items: center;
			font-size: 14px;
		}
	}
}

.sideNav {
	background-color: $darkblue;
	@include breakpoint($tablet) {
		padding: 40px 75px 0 0;
	}

	a {
		position: relative;
		display: block;
		height: 100px;
		padding-left: 15px;
		border-bottom: 1px solid #1B4465;
		font-size: 16px;
		font-weight: $bold;
		line-height: 100px;
		color: $white;
		z-index: 1;
		@include breakpoint($tablet) {
			height: 40px;
			border-bottom: none;
			border-left: 5px solid transparent;
			font-size: 12px;
			line-height: 40px;
		}

		svg {
			height: 45px;
			width: 45px;
			margin-right: 20px;
			fill: $lightgrey;
			vertical-align: middle;

			&:last-of-type {
				position: absolute;
				top: 50%;
				right: 15px;
				transform: translate(0, -50%);
				height: 12px;
				width: 8px;
				margin-right: 0;
				fill: $blue;
			}
		}

		&:last-of-type {
			border-bottom: none;
		}
		&.active {
			@include breakpoint($tablet) {
				border-left: 5px solid $blue;

				&:before {
					content: " ";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					@include bkgd-gradient(90deg, $blue, transparent);
					opacity: 0.25;
					z-index: -1;
				}
			}
		}
	}
}
