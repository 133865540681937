.Select {
	position: relative;
	margin-bottom: 5px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	
	div,
	input,
	span {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	
	&.is-disabled {
		& > .Select-control {
			background-color: #f9f9f9;
			
			&:hover {
				box-shadow: none;
			}
		}
		
		.Select-arrow-zone {
			cursor: default;
			pointer-events: none;
			opacity: 0.35;
		}
	}
}

.Select-control {
	background-color: $white;
	border-radius: 2px;
	cursor: default;
	display: table;
	border-spacing: 0;
	border-collapse: separate;
	outline: none;
	overflow: hidden;
	position: relative;
	width: 100%;
	
	.Select-input:focus {
		outline: none;
	}
}
.is-searchable.is-open > .Select-control {
	cursor: text;
}
.is-open {
	& > .Select-control {
		background: $white;
		
		.Select-arrow {
		}
	}
}
.is-searchable.is-focused:not(.is-open) > .Select-control {
	cursor: text;
}
.is-focused:not(.is-open) > .Select-control {
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
	bottom: 0;
	color: $darkgrey;
	left: 0;
	line-height: 30px;
	padding-left: 10px;
	padding-right: 10px;
	position: absolute;
	right: 0;
	top: 0;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
	color: #333;
}
.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
	cursor: pointer;
	text-decoration: none;
}
.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
	color: #007eff;
	outline: none;
	text-decoration: underline;
}

.Select-input {
	height: 30px;
	padding-left: 10px;
	padding-right: 10px;
	vertical-align: middle;
	
	& > input {
		width: 100%;
		background: none transparent;
		border: 0 none;
		box-shadow: none;
		cursor: default;
		display: inline-block;
		font-family: inherit;
		font-size: inherit;
		margin: 0;
		outline: none;
		line-height: 14px;
		/* For IE 8 compatibility */
		padding: 8px 0 7px;
		/* For IE 8 compatibility */
		-webkit-appearance: none;
	}
}
.is-focused .Select-input > input {
	cursor: text;
}
.has-value.is-pseudo-focused .Select-input {
	opacity: 0;
}
.Select-control:not(.is-searchable) > .Select-input {
	outline: none;
}
.Select-loading-zone {
	cursor: pointer;
	display: table-cell;
	position: relative;
	text-align: center;
	vertical-align: middle;
	width: 16px;
}
.Select-loading {
	-webkit-animation: Select-animation-spin 400ms infinite linear;
	-o-animation: Select-animation-spin 400ms infinite linear;
	animation: Select-animation-spin 400ms infinite linear;
	width: 16px;
	height: 16px;
	box-sizing: border-box;
	border-radius: 50%;
	border: 2px solid #ccc;
	border-right-color: #333;
	display: inline-block;
	position: relative;
	vertical-align: middle;
}
.Select-clear-zone {
	-webkit-animation: Select-animation-fadeIn 200ms;
	-o-animation: Select-animation-fadeIn 200ms;
	animation: Select-animation-fadeIn 200ms;
	color: $darkgrey;
	cursor: pointer;
	display: table-cell;
	position: relative;
	text-align: center;
	vertical-align: middle;
	width: 17px;
	
	&:hover {
		color: $red;
	}
}
.Select-clear {
	display: inline-block;
	font-size: 18px;
	line-height: 1;
}
.Select-arrow-zone {
	cursor: pointer;
	display: table-cell;
	position: relative;
	text-align: center;
	vertical-align: middle;
	width: 25px;
	padding-right: 4px;
}
.Select-arrow {
	display: inline-block;
	height: 8px;
	width: 12px;
	position: relative;
	background-image: url(svg-encode(down-arrow(#466B88)));
	background-repeat: no-repeat;
	background-position: left top;
	background-size: 12px 8px;
}
.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
}
.Select .Select-aria-only {
	display: inline-block;
	height: 1px;
	width: 1px;
	margin: -1px;
	clip: rect(0, 0, 0, 0);
	overflow: hidden;
	float: left;
}

.Select-menu-outer {
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
	background-color: $white;
	border: 1px solid $darkgrey;
	box-sizing: border-box;
	margin-top: -1px;
	max-height: 200px;
	position: absolute;
	/*top: 100%;*/
	width: 100%;
	z-index: 1;
	-webkit-overflow-scrolling: touch;
}
.menu-outer-top .Select-menu-outer {
  bottom: 35px!important;
  border-bottom-right-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
  border-top-right-radius: 4px!important;
  border-top-left-radius: 4px!important;
}
.Select-menu {
	max-height: 220px;
	overflow-y: auto;
}
.Select-option {
	box-sizing: border-box;
	background-color: $white;
	color: $darkblue;
	cursor: pointer;
	display: block;
	padding: 8px 10px;
	
	&:last-child {
	}
	&.is-selected, &:hover  {
		background-color: $meddarkgrey;
	}
	&.is-disabled {
		color: #cccccc;
		cursor: default;
	}
}

.Select-noresults {
	box-sizing: border-box;
	color: $darkblue;
	cursor: default;
	display: block;
	padding: 8px 10px;
}

.Select--multi {
	.Select-input {
		vertical-align: middle;
		margin-left: 10px;
		padding: 0;
	}
	.Select-multi-value-wrapper {
		display: inline-block;
	}
	.Select-value {
		background-color: #ebf5ff;
		/* Fallback color for IE 8 */
		background-color: rgba(0, 126, 255, 0.08);
		border-radius: 2px;
		border: 1px solid #c2e0ff;
		/* Fallback color for IE 8 */
		border: 1px solid rgba(0, 126, 255, 0.24);
		color: #007eff;
		display: inline-block;
		font-size: 0.9em;
		line-height: 1.4;
		margin-left: 5px;
		margin-top: 5px;
		vertical-align: top;
	}
	&.has-value .Select-input {
		margin-left: 5px;
	}
	.Select-clear-zone {
		width: 17px;
	}
	
	.Select-value-icon, .Select-value-label {
		display: inline-block;
		vertical-align: middle;
	}
	.Select-value-label {
		border-bottom-right-radius: 2px;
		border-top-right-radius: 2px;
		cursor: default;
		padding: 2px 5px;
	}
	a.Select-value-label {
		color: #007eff;
		cursor: pointer;
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
	.Select-value-icon {
		cursor: pointer;
		border-bottom-left-radius: 2px;
		border-top-left-radius: 2px;
		border-right: 1px solid #c2e0ff;
		/* Fallback color for IE 8 */
		border-right: 1px solid rgba(0, 126, 255, 0.24);
		padding: 1px 5px 3px;
	
		&:hover, &:focus {
			background-color: #d8eafd;
			/* Fallback color for IE 8 */
			background-color: rgba(0, 113, 230, 0.08);
			color: #0071e6;
		}
		&:active {
			background-color: #c2e0ff;
			/* Fallback color for IE 8 */
			background-color: rgba(0, 126, 255, 0.24);
		}
	}
	&.is-disabled {
		.Select-value {
			background-color: #fcfcfc;
			border: 1px solid #e3e3e3;
			color: #333;
		}
		.Select-value-icon {
			cursor: not-allowed;
			border-right: 1px solid #e3e3e3;
			
			&:hover, &:focus, &:active {
				background-color: #fcfcfc;
			}
		}
	}
}

@-webkit-keyframes Select-animation-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes Select-animation-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-webkit-keyframes Select-animation-spin {
	to {
		-webkit-transform: rotate(1turn);
	}
}
@keyframes Select-animation-spin {
	to {
		transform: rotate(1turn);
	}
}